<script setup>
import { ref, watch, onMounted, computed, defineProps } from 'vue';
import axios from 'axios';
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { getStrokeColor, getModeStrokeColor } from '../utils/commonFunctions.js';
import { getContentTypeLabel } from '../utils/commonFunctions.js';
import { useRouter } from 'vue-router';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useRemoveFromPlaylistStore } from '../store/removeFromPlaylist.js';
import { useDocumentPageFilterStore } from '../store/documentPageFilter.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';

import PageNavigator from '../components/PageNavigator.vue';
import CustomPagination from '../components/CustomPagination.vue';
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';
import AddOrCreatePlaylistTopBar from '../components/AddOrCreatePlaylistTopBar.vue';
import AddToPlaylistDropdown from '../components/AddToPlaylistDropdown.vue';
import RemoveFromPlaylistConfirmation from '../components/RemoveFromPlaylistConfirmation.vue';
import DocumentsCard from '../components/DocumentsCard.vue';
import DocumentSkeletonCard from '../components/DocumentSkeletonCard.vue';
import NotificationTopBar from '../components/NotificationTopBar.vue';

// import blackFilterIcon from '../assets/icons/black_filter_icon.svg';
// import whiteFilterIcon from '../assets/icons/white_filter_icon.svg';

import playlistImg from '../assets/blank_white_img.png';
import darkplaylistImg from '../assets/blank_dark_img.png';

const props = defineProps({
    id: Number,
    isTeacherPlaylist: Boolean
});

const addToPlaylistStore = useAddToPlaylistStore();
const removeFromPlaylistStore = useRemoveFromPlaylistStore();
const documentPageFilterStore = useDocumentPageFilterStore();
const modeStore = useModeStore();
const themeStore = useThemeStore();
const strokeColor = getStrokeColor();
const modeStrokeColor = getModeStrokeColor();
const activePageStore = useActivePageStore();
const notificationTopBarStore = useNotificationTopBarStore();
const showCreateEditModal = ref(false);
const isGridDisplayPattern = ref(false);
// const modalMode = ref('create');
const router = useRouter();

const searchQuery = ref('');
const searchResults = ref([]);

const currentPage = ref(1);
const itemsPerPage = ref(12); // Default itemsPerPage 10
const totalItems = ref(97);

const showingFrom = ref(1);

const showingTo = ref(1);

const totalPages = ref(1);

const changePage = (page) => {
    currentPage.value = page;
    if (props.isTeacherPlaylist == 'true') {
        fetchTeacherPlaylistAssetBroadcasts(page);
    } else {
        fetchUserPlaylistAssetBroadcasts(page);
    }
};

const assetBroadcastCurrentPage = ref(1);
const assetBroadcastItemsPerPage = ref(12);
const assetBroadcastTotalItems = ref(97);

const assetBroadcastShowingFrom = ref(1);

const assetBroadcastShowingTo = ref(1);

const assetBroadcastTotalPages = ref(1);

const assetBroadcastChangePage = (page) => {
    assetBroadcastCurrentPage.value = page;
    fetchAssetBroadcastList(page);
};

// Mock data
const searchData = ref([]);

// Function to perform search
const performSearch = () => {
  if (searchQuery.value.trim() !== '') {
    searchResults.value = searchData.value.filter(item =>
      item.title.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
    playlistAssetBroadcastList.value = searchResults.value;
  } else {
    searchResults.value = [];
  }
};

// Watch for changes in searchQuery to update search results
watch(searchQuery, () => {
  performSearch();
});

// const openCreateEditPlaylistModal = (mode) => {
//     modalMode.value = mode;
//     showCreateEditModal.value = true
// }

// const closeCreateEditPlaylistModal = () => {
//     document.body.classList.remove('overflow-hidden');
//     showCreateEditModal.value = false
// }

const openCreateEditPlaylistModal = () => {
    addToPlaylistStore.setModalMode('edit');
    addToPlaylistStore.setRedirectToCreatePlaylistPage(true);
    addToPlaylistStore.setShowCreateOrEditPlaylistModal(true);
    // showCreateEditModal.value = true
}

// const closeCreateEditPlaylistModal = () => {
//     addToPlaylistStore.setShowCreateOrEditPlaylistModal(false);
//     // showCreateEditModal.value = false
// }

watch(showCreateEditModal, (newValue) => {
    if (newValue) {
        // Add 'overflow-hidden' class to the body when modal is shown
        document.body.classList.add('overflow-hidden');
    } else {
        // Remove 'overflow-hidden' class when modal is hidden
        document.body.classList.remove('overflow-hidden');
    }
});

// const playlistData = ref({
//     name: 'Fizik Tingkatan 5',
//     createdBy: 'Cikgu Aznida',
//     grade: 5,
//     image: playlistImg,
//     createdbyAvatar: AzindaFaizal,
// })
const playlistData = ref(
    {
        playlist_thumbnail: playlistImg,
    }
)

const playlistThumbnail = computed(() => {
    // return playlistData.value.playlist_thumbnail ? playlistData.value.playlist_thumbnail : playlistImg;
    if (playlistData.value ) {
        if (playlistData.value.playlist_thumbnail) {
            return playlistData.value.playlist_thumbnail;
        } else {
            return modeStore.selectedMode === 'dark' ? darkplaylistImg : playlistImg;
        }
    } else {
        return modeStore.selectedMode === 'dark' ? darkplaylistImg : playlistImg;
    }
})

const playlistAssetBroadcastList = ref([])

// const playlistThumbnail = computed(() => {
//     return playlistData.value.playlist_thumbnail ? playlistData.value.playlist_thumbnail : AzindaFaizal;
// })

const isPlaylistDetailsLoading = ref(false);
const fetchTeacherPlaylistDetails = () => {
    isPlaylistDetailsLoading.value = true;
    // Fetch playlist details from API api/v1/playlists/{id}
    axios.get(`api/v1/playlists/${props.id}`)
        .then((response) => {
            playlistData.value = response.data;
            console.log('fetchTeacherPlaylistDetails result.data:', response.data);
        })
        .catch((error) => {
            console.error('Failed to fetch playlist details:', error);
        })
        .finally(() => {
            isPlaylistDetailsLoading.value = false;
        });
}

const isAddedToPlaylist = (id) => {
    return assetBroadcastIdsInPlaylist.value.includes(id);
};

const assetBroadcastIdsInPlaylist = ref([]);
const fetchUserPlaylistDetails = () => {
    isPlaylistDetailsLoading.value = true;
    // Fetch playlist details from API api/v1/user-playlists/{id}
    axios.get(`api/v1/user-playlists/${props.id}`)
        .then((response) => {
            playlistData.value = response.data;
            console.log('fetchUserPlaylistDetails result.data:', response.data);
            assetBroadcastIdsInPlaylist.value = response.data.asset_broadcasts_ids;
        })
        .catch((error) => {
            console.error('Failed to fetch playlist details:', error);
        })
        .finally(() => {
            isPlaylistDetailsLoading.value = false;
        });
}

const fetchTeacherPlaylistAssetBroadcasts = (page = 1) => {
    // Fetch asset broadcasts from API api/v1/playlists/{id}/asset-broadcasts
    isAssetBroadcastListLoading.value = true;
    axios.get(`api/v1/playlists/${props.id}/asset-broadcasts?page=${page}`, {
        params: {
            content_type: documentPageFilterStore.filterByDocumentType === 'all' ? null : documentPageFilterStore.filterByDocumentType,
            uploader_id: documentPageFilterStore.filterByUploadedBy === 'admin' ? null : documentPageFilterStore.filterByUploadedBy,
            user_group_id: documentPageFilterStore.filterByCourseLevel === 'all' ? null : documentPageFilterStore.filterByCourseLevel,
            tag_id: documentPageFilterStore.filterBySubject === 'all' ? null : documentPageFilterStore.filterBySubject,
        }
    })
        .then((response) => {
            console.log('fetchTeacherPlaylistAssetBroadcasts result.data:', response.data);
            playlistAssetBroadcastList.value = response.data.data;
            playlistAssetBroadcastList.value = response.data.data.map(document => ({
            ...document,
            isHovered: false
        }));
            totalItems.value = response.data.total;
            currentPage.value = response.data.current_page;
            searchData.value = response.data;
            showingFrom.value = response.data.from;
            showingTo.value = response.data.to;
            totalPages.value = response.data.last_page
        })
        .catch((error) => {
            console.error('Failed to fetch asset broadcasts:', error);
        })
        .finally(() =>{
            isAssetBroadcastListLoading.value = false;
        });
}

const fetchUserPlaylistAssetBroadcasts = (page = 1) => {
    // Fetch asset broadcasts from API api/v1/user-playlists/{id}/asset-broadcasts
    axios.get(`api/v1/user-playlists/${props.id}/asset-broadcasts?page=${page}`)
        .then((response) => {
            console.log('fetchUserPlaylistAssetBroadcasts result.data:', response.data);
            playlistAssetBroadcastList.value = response.data.data;
            totalItems.value = response.data.total;
            currentPage.value = response.data.current_page;
            searchData.value = response.data;
            showingFrom.value = response.data.from;
            showingTo.value = response.data.to;
            totalPages.value = response.data.last_page
        })
        .catch((error) => {
            console.error('Failed to fetch asset broadcasts:', error);
        });
}


const showRemoveFromPlaylistConfirmation = (fileId) => {
    removeFromPlaylistStore.fileIdToRemove = fileId;
    removeFromPlaylistStore.playlistIdToRemoveFrom = props.id;
    removeFromPlaylistStore.isShowRemoveFromPlaylistConfirmationModal = true;

}

// const closeRemoveFromPlaylistConfirmation = () => {
//     filesToRemoveFromPlaylist.value = [];
//     isShowRemoveFromPlaylistConfirmation.value = false;
// }

// const removeFromPlaylist = (assetBroadcastId) => {
//     // Remove asset broadcast from playlist
//     axios.delete(`api/v1/user-playlists/${props.id}/asset-broadcasts/${assetBroadcastId}`)
//         .then((response) => {
//             console.log('removeFromPlaylist result.data:', response.data);
//             fetchUserPlaylistAssetBroadcasts(1);
//         })
//         .catch((error) => {
//             console.error('Failed to remove asset broadcast:', error);
//         });
// }

const assetBroadcastList = ref([]);
const isAssetBroadcastListLoading = ref(false);
function fetchAssetBroadcastList(page = 1) {
    isAssetBroadcastListLoading.value = true;
    axios.get(`api/v1/asset-broadcasts?page=${page}`).then(response => {
        console.log('fetchAssetBroadcastList response', response.data);
        assetBroadcastList.value = response.data.data;
        assetBroadcastTotalItems.value = response.data.total;
        assetBroadcastCurrentPage.value = response.data.current_page;
        assetBroadcastShowingFrom.value = response.data.from;
        assetBroadcastShowingTo.value = response.data.to;
        assetBroadcastTotalPages.value = response.data.last_page
    }).catch(error => {
        console.error('Failed to fetch playlists data:', error);
    }).finally(() => {
        isAssetBroadcastListLoading.value = false
    });
}

const isAddToPlaylistLoading = ref(false);
const addToPlaylist = (id) => {
    isAddToPlaylistLoading.value = true;
    console.log('Add to playlist', id);
    axios.post(`api/v1/user-playlists/${props.id}/single-asset-broadcasts`, {
        asset_broadcast_id: id,
    })
    .then((response) => {
        console.log('addToPlaylist response =====', response);
        fetchAssetBroadcastList(1);
        fetchUserPlaylistAssetBroadcasts(1);
    })
    .catch((error) => {
        console.log(error);
    })
    .finally(() => {
        isAddToPlaylistLoading.value = false;
    });
};

const documentTypes = ref(null);
const uploaders = ref(null);
const courseLevels = ref(null);
const subjects = ref(null);

const fetchFilterData = () => {
    axios.get('api/v1/asset-broadcasts/filters')
        .then(response => {
            // Update the filter options with the received data
            documentTypes.value = response.data.assetTypes;
            uploaders.value = response.data.uploadedBy;
            courseLevels.value = response.data.courseLevel;
            subjects.value = response.data.subjects;
        })
        .catch(error => {
            console.error('Failed to fetch filter data:', error);
        });
};

const toggleFilter = () => {
    documentPageFilterStore.toggleFilterDisplay();
};

const resetFilter = () => {
    documentPageFilterStore.resetFilter();
    fetchDocuments();
};

watch(
  () => [
    documentPageFilterStore.filterByDocumentType,
    documentPageFilterStore.filterByUploadedBy,
    documentPageFilterStore.filterByCourseLevel,
    documentPageFilterStore.filterBySubject,
  ],
  (newValues) => {
    console.log('Filters changed:', newValues);
    console.log('documentPageFilterStore.filterBySubject', documentPageFilterStore.filterBySubject);
    currentPage.value = 1;
    fetchDocuments();
  }
);


onMounted(() => {
    addToPlaylistStore.closeAddOrCreatePlaylistTopBar();
    activePageStore.setSelectedActivePage(6)
    console.log('Document page mounted, filter');

    fetchFilterData();
    if (props.isTeacherPlaylist == 'true') {
        fetchTeacherPlaylistDetails();
        fetchTeacherPlaylistAssetBroadcasts(1);
    } else {
        console.log('fetchUserPlaylistDetails props.isTeacherPlaylist:', props.isTeacherPlaylist)
        fetchUserPlaylistDetails();
        fetchUserPlaylistAssetBroadcasts(1);
        fetchAssetBroadcastList(1);
    }
});

const goToPlaylistItem = (assetBroadcastId) => {
    // playlist play
    router.push({
        name: 'Play Playlist',
        params: { id: props.id },
        query: { 
            isTeacherPlaylist: props.isTeacherPlaylist, 
            assetId: assetBroadcastId 
        }
    });
}

const showBarAndUpdateSelectedFile = (id) => {
    const checkbox = document.getElementById('checkbox-' + id);
    
    if (checkbox && checkbox.checked) {
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(true);
        addToPlaylistStore.setSelectedAssetBroadcastIds([...addToPlaylistStore.selectedAssetBroadcastIds, id]);
    } else { //unchecked
        addToPlaylistStore.setSelectedAssetBroadcastIds(addToPlaylistStore.selectedAssetBroadcastIds.filter(fileId => fileId !== id));
    }

    console.log('selectedAssetBroadcastIds', addToPlaylistStore.selectedAssetBroadcastIds);
}

const goToDocumentPageWithSubjectFilter = (id) => {
    console.log('goToDocumentPageWithSubjectFilter', id);
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterBySubject(id);
    router.push('/document');
}

const goToDocumentPageWithCourseLvlFilter = (id) => {
    console.log('goToDocumentPageWithCourseLvlFilter', id); 
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterByCourseLevel(id);
    router.push('/document');
}

const isAddRemoveWorkspaceLoading = ref(false);

const addToWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.post('api/v1/workspaces/asset_broadcasts', {
            asset_broadcast_id: id
        });
        if (props.isTeacherPlaylist == 'true') {
            fetchTeacherPlaylistDetails();
            fetchTeacherPlaylistAssetBroadcasts(1);
        } else {
            console.log('fetchUserPlaylistDetails props.isTeacherPlaylist:', props.isTeacherPlaylist)
            fetchUserPlaylistDetails();
            fetchUserPlaylistAssetBroadcasts(1);
            fetchAssetBroadcastList(1);
        }
    } catch (error) {
        console.error('Error adding asset broadcast to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}


const removeFromWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/asset_broadcasts/${id}`);
        if (props.isTeacherPlaylist == 'true') {
            fetchTeacherPlaylistDetails();
            fetchTeacherPlaylistAssetBroadcasts(1);
        } else {
            console.log('fetchUserPlaylistDetails props.isTeacherPlaylist:', props.isTeacherPlaylist)
            fetchUserPlaylistDetails();
            fetchUserPlaylistAssetBroadcasts(1);
            fetchAssetBroadcastList(1);
        }        
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const fetchDocuments = async () => {
    console.log('Document page fetch, filter');

    try {
        if (props.isTeacherPlaylist == 'true') {
            fetchTeacherPlaylistDetails();
            fetchTeacherPlaylistAssetBroadcasts(1);
        } else {
            console.log('fetchUserPlaylistDetails props.isTeacherPlaylist:', props.isTeacherPlaylist)
            fetchUserPlaylistDetails();
            fetchUserPlaylistAssetBroadcasts(1);
            fetchAssetBroadcastList(1);
        }        
    } catch (error) {
        console.error('Error fetching playlist:', error);
    }
}

// const changeDisplayPattern = () => {
//     isGridDisplayPattern.value = !isGridDisplayPattern.value;
// };

const breadcrumbs = [
  { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
  { text: 'Playlist', to: { name: 'Playlist Page' } },
  { text: props.isTeacherPlaylist == 'true' ? 'Playlist Cikgu' : 'Playlist Anda' },
];

</script>

<template>
    <div class="min-h-screen">
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" />
        <AddOrCreatePlaylistTopBar v-show="addToPlaylistStore.showAddOrCreatePlaylistTopBar" />
        <AddToPlaylistDropdown v-show="addToPlaylistStore.showAddToPlaylistDropdown" />
        <CreateEditPlaylistModal :playlistId="props.id" v-show="addToPlaylistStore.showCreateOrEditPlaylistModal" :isAtPlaylistPages="true"/>
        <RemoveFromPlaylistConfirmation @refresh-fetchUserPlaylistAssetBroadcasts="fetchUserPlaylistAssetBroadcasts(1)" v-if="removeFromPlaylistStore.isShowRemoveFromPlaylistConfirmationModal" />
        <!-- <CreateEditPlaylistModal v-if="showCreateEditModal" :mode=modalMode :playlistId="props.id" @close-create-edit-playlist-modal="closeCreateEditPlaylistModal"/> -->
        <!-- <page-navigator from="Playlist" :to="playlistData.name" /> -->
        <page-navigator :breadcrumbs="breadcrumbs" />
        
        <div v-if="!isPlaylistDetailsLoading && playlistData && props.isTeacherPlaylist == 'false'" class="flex gap-2 px-6 py-4 my-4 rounded-xl relative overflow-hidden" style="backdrop-filter: blur(8px); border: 0px solid rgba(255, 255, 255, 0.2); box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);">
            <img :src="playlistThumbnail" alt="playlist image" class="absolute inset-0 w-full h-full object-cover filter blur-sm" />
            <div class="px-4 my-auto relative z-10 flex h-20 md:h-60 w-full">
                <!-- <div class="relative">
                    <div v-if="playlistData.playlist_thumbnail">
                        <img :src="playlistData.playlist_thumbnail" class="h-20 md:h-60 shadow-lg" alt="playlist image" />
                    </div>
                    <div v-else>
                        <div :class="modeStore.selectedMode === 'light'? 'bg-gray-400': 'bg-[black]'" class="h-20 md:h-60 w-60 rounded-xl"></div>
                    </div>
                        <svg @click="openCreateEditPlaylistModal('edit')" v-show="props.isTeacherPlaylist == 'false'" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-10 md:h-16 cursor-pointer" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M36.667 6.66602H30.0003C13.3337 6.66602 6.66699 13.3327 6.66699 29.9994V49.9994C6.66699 66.666 13.3337 73.3327 30.0003 73.3327H50.0003C66.667 73.3327 73.3337 66.666 73.3337 49.9994V43.3327" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M53.4663 10.0675L27.1996 36.3342C26.1996 37.3342 25.1996 39.3008 24.9996 40.7342L23.5663 50.7675C23.0329 54.4008 25.5996 56.9342 29.2329 56.4342L39.2663 55.0008C40.6663 54.8008 42.6329 53.8008 43.6663 52.8008L69.9329 26.5342C74.4663 22.0008 76.5996 16.7342 69.9329 10.0675C63.2663 3.40084 57.9996 5.53418 53.4663 10.0675Z" stroke="white" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M49.7002 13.834C51.9335 21.8007 58.1669 28.034 66.1669 30.3007" stroke="white" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                </div> -->
                <div class="">
                    <div>
                        <button @click="openCreateEditPlaylistModal('edit')" class="absolute right-4 flex justify-center gap-1 py-2 px-4 rounded-md bg-black text-white cursor-pointer text-center">
                            <div>
                                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.459 2.08203H9.37565C4.16732 2.08203 2.08398 4.16536 2.08398 9.3737V15.6237C2.08398 20.832 4.16732 22.9154 9.37565 22.9154H15.6257C20.834 22.9154 22.9173 20.832 22.9173 15.6237V13.5404" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.7075 3.14451L8.49914 11.3528C8.18664 11.6653 7.87414 12.2799 7.81164 12.7278L7.36372 15.8633C7.19706 16.9987 7.99914 17.7903 9.13456 17.6341L12.27 17.1862C12.7075 17.1237 13.3221 16.8112 13.645 16.4987L21.8533 8.29034C23.27 6.87368 23.9366 5.22784 21.8533 3.14451C19.77 1.06118 18.1241 1.72784 16.7075 3.14451Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M15.5312 4.32422C16.2292 6.8138 18.1771 8.76172 20.6771 9.47005" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                            </div>
                            <div>Sunting Playlist</div>
                        </button>
                    </div>   
                </div>
                <div :class="modeStore.selectedMode === 'light'? 'text-black': 'text-white'" class="px-4 my-auto">
                    <h1 class="text-xl md:text-5xl font-bold py-2">{{ playlistData.title }}</h1>
                    <div v-if="playlistData.created_by" class="flex gap-2">
                        <div v-if="playlistData.created_by.avatar_url" class="max-w-6">
                            <img :src="playlistData.created_by.avatar_url" alt="created by avatar" />
                        </div>
                        <p>{{ $t('general.by') }} {{ playlistData.created_by.name }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!isPlaylistDetailsLoading && playlistData && props.isTeacherPlaylist == 'true'">
            <div class="grid grid-cols-12 p-[30px]">
                <div class="col-span-3">
                    <img :src="playlistThumbnail" alt="playlist image" class="w-full h-full object-cover rounded-xl" />
                </div>
                <div class="col-span-9">
                    <div :class="modeStore.selectedMode === 'light'? 'text-black': 'text-white'" class="px-4 my-auto">
                    <h1 class="text-xl md:text-5xl font-bold py-2">{{ playlistData.title }}</h1>
                    <div v-if="playlistData.created_by" class="flex gap-2">
                        <div v-if="playlistData.created_by.avatar_url" class="max-w-6">
                            <img :src="playlistData.created_by.avatar_url" alt="created by avatar" />
                        </div>
                        <p>{{ $t('general.by') }} {{ playlistData.created_by.name }}</p>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div v-show="!isPlaylistDetailsLoading && playlistAssetBroadcastList.length > 0" class="flex justify-between justify-items-center my-auto p-[30px]">
            <h2 :class="{
                'text-black': modeStore.selectedMode === 'light',
                'text-white': modeStore.selectedMode !== 'light',
                'mobile-title': isMobile,
                'title': !isMobile
            }">{{ $t('playlistPage.addedVideo')}}</h2>
            <div class="my-auto h-10">
                <div class="flex gap-1 justify-between h-10 my-auto">
                    <!-- <div @click="changeDisplayPattern()" class="hidden md:block w-8 my-auto cursor-pointer">
                        <svg v-if="isGridDisplayPattern" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.75 8.75H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                        <path d="M3.75 15H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                        <path d="M3.75 21.25H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                        </svg>
                        <img v-else class="my-auto" :src="modeStore.selectedMode === 'dark' ? whiteDisplayInGridIcon : blackDisplayInGridIcon" alt="display in grid icon" />
                        <svg  width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M27.5 10.65V4.975C27.5 3.2125 26.7 2.5 24.7125 2.5H19.6625C17.675 2.5 16.875 3.2125 16.875 4.975V10.6375C16.875 12.4125 17.675 13.1125 19.6625 13.1125H24.7125C26.7 13.125 27.5 12.4125 27.5 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M27.5 24.7125V19.6625C27.5 17.675 26.7 16.875 24.7125 16.875H19.6625C17.675 16.875 16.875 17.675 16.875 19.6625V24.7125C16.875 26.7 17.675 27.5 19.6625 27.5H24.7125C26.7 27.5 27.5 26.7 27.5 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.125 10.65V4.975C13.125 3.2125 12.325 2.5 10.3375 2.5H5.2875C3.3 2.5 2.5 3.2125 2.5 4.975V10.6375C2.5 12.4125 3.3 13.1125 5.2875 13.1125H10.3375C12.325 13.125 13.125 12.4125 13.125 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M13.125 24.7125V19.6625C13.125 17.675 12.325 16.875 10.3375 16.875H5.2875C3.3 16.875 2.5 17.675 2.5 19.6625V24.7125C2.5 26.7 3.3 27.5 5.2875 27.5H10.3375C12.325 27.5 13.125 26.7 13.125 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div> -->
                    <div class="w-8 my-auto cursor-pointer">
                        <!-- <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 27.5C21.875 27.5 27.5 21.875 27.5 15C27.5 8.125 21.875 2.5 15 2.5C8.125 2.5 2.5 8.125 2.5 15C2.5 21.875 8.125 27.5 15 27.5Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 10V16.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14.9922 20H15.0034" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> -->
                        <!-- <img :src="modeStore.selectedMode === 'dark' ? whiteInfoCircle : blackInfoCircle" alt="info circle icon" /> -->
                    </div>
                    <div @click="toggleFilter()" class="w-8 my-auto cursor-pointer">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.75 2.62402H23.25C24.625 2.62402 25.75 3.74902 25.75 5.12402V7.87402C25.75 8.87402 25.125 10.124 24.5 10.749L19.125 15.499C18.375 16.124 17.875 17.374 17.875 18.374V23.749C17.875 24.499 17.375 25.499 16.75 25.874L15 26.999C13.375 27.999 11.125 26.874 11.125 24.874V18.249C11.125 17.374 10.625 16.249 10.125 15.624L5.375 10.624C4.75 9.99902 4.25 8.87402 4.25 8.12402V5.24902C4.25 3.74902 5.375 2.62402 6.75 2.62402Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <!-- <img :src="modeStore.selectedMode === 'dark' ? whiteFilterIcon : blackFilterIcon" alt="search icon" /> -->
                    </div>
                </div>
            </div>
        </div>

        <!--Filters-->
        <div v-if="documentPageFilterStore.isFilterDisplay && !isAssetBroadcastListLoading" class="px-[30px]">
                <div class="flex justify-between">
                    <h3 class="text-xl">{{ $t('documentPage.filter')}}</h3>
                    <div @click="resetFilter()" class="cursor-pointer">
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.9173 13.0007C22.9173 18.7507 18.2507 23.4173 12.5007 23.4173C6.75065 23.4173 3.24023 17.6257 3.24023 17.6257M3.24023 17.6257H7.94857M3.24023 17.6257V22.834M2.08398 13.0007C2.08398 7.25065 6.70898 2.58398 12.5007 2.58398C19.4486 2.58398 22.9173 8.37565 22.9173 8.37565M22.9173 8.37565V3.16732M22.9173 8.37565H18.2923" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <!-- <div @click="resetFilter()" class="text-red-400 cursor-pointer">{{ $t('documentPage.resetFilter')}}</div> -->
                </div>
            
                <div class="grid md:grid-cols-4 gap-10 py-4">
                    <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                        <!-- <p>{{ $t('documentPage.fileType')}}</p> -->
                        <select v-model="documentPageFilterStore.filterByDocumentType" 
                            :class="docume"
                            class="w-full py-2 px-4 my-2 rounded-md border-[1px] border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white hover:cursor-pointer">
                            <option value="all" class="text-black">{{ $t('documentPage.fileType')}}</option>
                            <option value="all" class="text-black">{{ $t('documentPage.all')}}</option>
                            <option v-for="type in documentTypes" :value="type" :key="type" class="text-black">{{ type }}</option>
                        </select>
                    </div>
                    <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                        <!-- <p>{{ $t('documentPage.uploadedBy')}}</p> -->
                        <select v-model="documentPageFilterStore.filterByUploadedBy" class="w-full py-2 px-4 my-2 rounded-md border-[1px]  border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white cursor-pointer">
                            <option value='admin' class="text-black">{{ $t('documentPage.uploadedBy')}}</option>
                            <option v-for="uploader in uploaders" :value="uploader.id" :key="uploader.id" class="text-black">{{ uploader.name }}</option>
                        </select>
                    </div>
                    <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                        <!-- <p>{{ $t('documentPage.grade')}}</p> -->
                        <select v-model="documentPageFilterStore.filterByCourseLevel" class="w-full py-2 px-4 my-2 rounded-md border-[1px] border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white cursor-pointer">
                            <option value="all" class="text-black">{{ $t('documentPage.grade')}}</option>
                            <option value="all" class="text-black">Pilih satu pilihan</option>
                            <option v-for="level in courseLevels" :value="level.id" :key="level.id" class="text-black">{{ level.name }}</option>
                        </select>
                    </div>
                    <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                        <!-- <p>{{ $t('documentPage.subject')}}</p> -->
                        <select v-model="documentPageFilterStore.filterBySubject" class="w-full py-2 px-4 my-2 rounded-md border-[1px] border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white cursor-pointer">
                            <option value="all" class="text-black">{{ $t('documentPage.subject')}}</option>
                            <option value="all" class="text-black">Semua</option>
                            <option v-for="subject in subjects" :value="subject.id" :key="subject.id" class="text-black">{{ subject.name }}</option>
                        </select>
                    </div>
                    <!-- <div>
                        <p>{{ $t('documentPage.uploadDate')}}</p>
                        <VueDatePicker v-model="documentPageFilterStore.filterByDate" :enable-time-picker="false"  :dark="modeStore.selectedMode === 'dark'"
                         class="w-[70%] py-2 rounded-md" />
                    </div> -->
                </div>
                
            </div>

        <section v-show="playlistAssetBroadcastList.length > 0" 
            class="flex flex-col gap-8 rounded-xl p-[30px]"
            :class="{'text-black': modeStore.selectedMode === 'light', 'bg-black bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
        >

            <div v-show="isPlaylistDetailsLoading" class="flex items-center justify-center w-full gap-2">
                <div>{{ $t('general.loading')}}</div>
                <span class="loading loading-bars loading-sm"></span>
            </div>

            <!-- <div v-show="props.isTeacherPlaylist == 'false' && playlistAssetBroadcastList.length > 0" :class="modeStore.selectedMode === 'light'? 'text-black': 'text-white'" class="text-2xl font-bold">
                Video Yang Ditambah
            </div> -->

            <!--grid layout view -->
            <div v-if="isGridDisplayPattern && isAssetBroadcastListLoading" class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
            </div>

            <div v-if="isGridDisplayPattern && !isAssetBroadcastListLoading" class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <DocumentsCard
                        class="md:min-w-0 lg:w-full"
                        v-for="file in playlistAssetBroadcastList"
                        :key="file.id"
                        :id="file.id"
                        :image="file.thumbnails[0]?.url"
                        :topic="file.title"
                        :groups="file.groups ? file.groups : []"
                        :subjects="file.subjects ? file.subjects : []"
                        :postedTime="file.created_at_formatted"
                        :tags="file.tags"
                        :isAddedToWorkspace="file.has_added_to_workspace"
                        :contentType="file.content_type"
                        @refresh-asset-api="fetchDocuments"
                    />
            </div>

            <!--rows layout view -->
            <table v-else v-show="!isAssetBroadcastListLoading && playlistAssetBroadcastList.length > 0">
                <thead class=" text-base ">
                    <tr class="grid grid-cols-12 py-2 px-6">
                        <th :class="props.isTeacherPlaylist === 'true'? '': 'hidden'" class="col-span-1 invisible">checkbox</th>
                        <th class="col-span-10 md:col-span-6 text-center">{{ $t('playlistPage.title')}}</th>
                        <th class="hidden md:block col-span-3 text-center">{{ $t('playlistPage.fileType')}}</th>
                        <!-- <th class="hidden md:block col-span-2 text-center">{{ $t('playlistPage.dateAdded')}}</th> -->
                        <th class="col-span-2 text-center">{{ $t('playlistPage.action')}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="file in playlistAssetBroadcastList" :key="file.id" :class="{'hover:bg-white hover:bg-opacity-10': modeStore.selectedMode === 'dark', 'hover:bg-white hover:bg-opacity-20':modeStore.selectedMode === 'light'}" class="grid grid-cols-12 py-4 px-6">
                        <th class="col-span-1 text-center my-auto" :class="props.isTeacherPlaylist === 'true'? '': 'hidden'">
                            <input ref="checkbox" @click="showBarAndUpdateSelectedFile(file.id)" type="checkbox" :id="'checkbox-' + file.id" class="file-checkbox h-5 w-5"/>
                        </th>
                        <td class="col-span-10 md:col-span-6 text-left my-auto cursor-pointer">
                            <div class="flex gap-2">
                                <img :src="file.thumbnails[0].url"
                                v-if="file.thumbnails.length > 0"    
                                @click="goToPlaylistItem(file.id)"
                                alt="course img" class="cursor-pointer max-h-16 max-w-16 rounded-md">
                                <div class="my-auto">
                                    <div @click="goToPlaylistItem(file.id)" class="cursor-pointer my-auto font-semibold">{{ file.title }}</div>
                                    <div class="flex overflow-hidden text-sm">
                                        <div v-if="file.groups && file.groups.length > 0">
                                            <div  v-for="(group, index) in file.groups" :key="group.id" class="flex overflow-hidden">
                                                <div class="cursor-pointer" @click="goToDocumentPageWithCourseLvlFilter(group.id)">{{ group.name }}</div><span v-if="index < file.groups.length - 1">, </span>
                                            </div>
                                        </div>
                                        <div v-if="file.subjects && file.subjects.length > 0">
                                            <span class="px-1"> | </span>
                                        </div>
                                        <div v-if="file.subjects && file.subjects.length > 0">
                                            <div v-for="(subject, index) in file.subjects" :key="subject.id" class="flex overflow-hidden">
                                                <div class="cursor-pointer" @click="goToDocumentPageWithSubjectFilter(subject.id)">{{ subject.name }}</div><span v-if="index < file.subjects.length - 1">, </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td @click="goToPlaylistItem(file.id)" class="hidden md:block col-span-3 text-center my-auto cursor-pointer">
                            <div class="text-center">
                            <div class="badge badge-lg border-0 rounded-xl h-auto" :class="{
                                'bg-[#FEF3C7] text-[#92400E]': file.content_type === 1,
                                'bg-green-100 text-green-800': file.content_type === 2,
                                'bg-purple-100 text-purple-800': file.content_type === 3
                            }">
                            {{ getContentTypeLabel(file.content_type) }}
                                <!-- <p v-if="file.content_type === 1">{{ $t('general.video')}}</p>
                                <p v-if="file.content_type === 2">{{ $t('general.interactive')}}</p>
                                <p v-if="file.content_type === 3">{{ $t('general.document')}}</p> -->
                            </div>
                            </div>
                        </td>
                        <!-- <td v-for="tag in file.tags" :key="tag" class="col-span-2 text-center my-auto">
                            <div class="badge badge-lg border-0 rounded-xl bg-[#FEF3C7] text-[#92400E]">
                                {{ tag }}
                            </div>
                        </td> -->
                        <!-- <td @click="goToPlaylistItem(file.id)" class="hidden md:block col-span-2 text-center my-auto cursor-pointer">{{ file.created_at_formatted }}</td> -->
                        <td class="col-span-2 text-center my-auto mx-auto flex gap-2" 
                        :class="{ 'theme-green': themeStore.selectedTheme === 'green', 'theme-orange': themeStore.selectedTheme === 'orange', 'theme-blue': themeStore.selectedTheme === 'blue', 'theme-purple': themeStore.selectedTheme === 'purple', 'theme-darkpink': themeStore.selectedTheme === 'darkpink'  }">
                            <!-- <div class="relative">
                                <AddToPlaylistDropdown :currentFileId="file.id"/>
                            </div> -->
                            <!-- <input v-if="props.isTeacherPlaylist == 'true'" ref="checkbox" @click="showBarAndUpdateSelectedFile(file.id)" type="checkbox" :id="'checkbox-' + file.id" class="file-checkbox h-5 w-5"/> -->
                            <div v-if="props.isTeacherPlaylist === 'true'">
                                <div v-if="file.has_added_to_workspace" @click="removeFromWorkspace(file.id)"  class="cursor-pointer">
                                    <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                    <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="strokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>  
                                </div>
                                <div v-else @click="addToWorkspace(file.id)" class="cursor-pointer text-black" @mouseover="file.isHovered = true" @mouseout="file.isHovered = false">
                                    <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                    <svg v-show="!isAddRemoveWorkspaceLoading" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M32.5 21.25V26.25C32.5 30.625 30 32.5 26.25 32.5H13.75C10 32.5 7.5 30.625 7.5 26.25V21.25C7.5 17.9375 8.9375 16.0625 11.25 15.35C12 15.1125 12.8375 15 13.75 15H26.25C27.1625 15 28 15.1125 28.75 15.35C31.0625 16.0625 32.5 17.9375 32.5 21.25Z" :stroke="file.isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M28.75 13.75V15.35C28 15.1125 27.1625 15 26.25 15H13.75C12.8375 15 12 15.1125 11.25 15.35V13.75C11.25 12.375 12.375 11.25 13.75 11.25H26.25C27.625 11.25 28.75 12.375 28.75 13.75Z" :stroke="file.isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M25 9.38751V11.25H15V9.38751C15 8.35001 15.85 7.5 16.8875 7.5H23.1125C24.15 7.5 25 8.35001 25 9.38751Z" :stroke="file.isHovered ? strokeColor : modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>  
                                </div>
                            </div>
                            <div v-if="props.isTeacherPlaylist == 'false'" @click="showRemoveFromPlaylistConfirmation(file.id)">
                                <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.25 13H18.75" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>

                        </td>

                    </tr>
                </tbody>
                <div>
                    <div v-show="!isPlaylistDetailsLoading && playlistAssetBroadcastList.length === 0 && props.isTeacherPlaylist == 'true'" class="text-center py-4">
                        <h2 class="text-2xl text-center font-bold">{{ $t('general.noData')}}</h2>
                    </div>
                    <div v-show="!isPlaylistDetailsLoading && playlistAssetBroadcastList.length === 0 && props.isTeacherPlaylist == 'false'" class="text-center py-4">
                        <router-link :to="`/playlist/create?id=${$props.id}`" class="text-center py-4 rounded-md">
                            <button :data-theme='themeStore.selectedTheme' class="text-sm py-2 px-4 rounded-lg text-white cursor-pointer">Add item</button>
                        </router-link>
                    </div>
                </div>
            </table>

            <!-- Pagination -->
            <div class="hidden lg:block">
                <CustomPagination
                    v-if="!isPlaylistDetailsLoading && playlistAssetBroadcastList.length > 0"
                    :showingFrom="showingFrom"
                    :showingTo="showingTo"
                    :totalItems="totalItems"
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    :itemsPerPage="itemsPerPage"
                    @change-page="changePage"
                />
            </div>
        </section>

        <!-- recommended aseetbroadcast -->
        <section 
            v-show="props.isTeacherPlaylist == 'false'"
            class="flex flex-col gap-8 rounded-xl p-[30px] mt-[30px]"
            :class="{'text-black': modeStore.selectedMode === 'light', 'bg-black bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
        >
            <div :class="modeStore.selectedMode === 'light'? 'text-black': 'text-white'" class="text-2xl font-bold">
                Koleksi Utama Disyorkan
            </div>
            
            <table>
                <thead class="text-base font-semibold ">
                    <tr class="grid grid-cols-12 py-2 px-6">
                        <th class="col-span-1 invisible">checkbox</th>
                        <th class="col-span-10 md:col-span-6 text-center">{{ $t('playlistPage.title')}}</th>
                        <th class="hidden md:block col-span-3 text-center">{{ $t('playlistPage.fileType')}}</th>
                        <th class="col-span-2 text-center">{{ $t('playlistPage.action')}}</th>
                    </tr>
                </thead>
                <div v-show="isAssetBroadcastListLoading" class="flex items-center justify-center w-full gap-2 p-6">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div>
                    <div v-for="item in assetBroadcastList" :key="item.id" :class="{'hover:bg-white hover:bg-opacity-10': modeStore.selectedMode === 'dark', 'hover:bg-white hover:bg-opacity-20':modeStore.selectedMode === 'light'}" >
                        <div v-if="!isAddedToPlaylist(item.id)" class="grid grid-cols-12 py-3 md:px-6 w-full">
                            <th class="col-span-1 text-center my-auto">
                                <input ref="checkbox" @click="showBarAndUpdateSelectedFile(item.id)" type="checkbox" :id="'checkbox-' + item.id" class="file-checkbox h-5 w-5"/>
                            </th>
                            <div  class="col-span-10 md:col-span-6 flex gap-4">
                                <div @click="goToDocument(item.id)" class="min-w-12 w-12 h-12 rounded-full">
                                    <img :src="item.thumbnails[0]?.url" alt="playlist image" class="w-full h-full object-cover">
                                </div>
                                <div class="my-auto">
                                    <p @click="goToDocument(item.id)" class="text-base font-semibold">{{ item.title }}</p>
                                    <div class="flex overflow-hidden text-sm">
                                        <div v-if="item.groups && item.groups.length > 0">
                                            <div  v-for="(group, index) in item.groups" :key="group.id" class="flex overflow-hidden">
                                                <div class="cursor-pointer" @click="goToDocumentPageWithCourseLvlFilter(group.id)">{{ group.name }}</div><span v-if="index < item.groups.length - 1">, </span>
                                            </div>
                                        </div>
                                        <div v-if="item.subjects && item.subjects.length > 0">
                                            <span class="px-1"> | </span>
                                        </div>
                                        <div v-if="item.subjects && item.subjects.length > 0">
                                            <div v-for="(subject, index) in item.subjects" :key="subject.id" class="flex overflow-hidden">
                                                <div class="cursor-pointer" @click="goToDocumentPageWithSubjectFilter(subject.id)">{{ subject.name }}</div><span v-if="index < item.subjects.length - 1">, </span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <td @click="goToDocument(item.id)" class="hidden md:block col-span-3 text-center my-auto">
                                <div             
                            :class="{
                                'bg-[#FEF3C7] text-[#92400E]': item.content_type === 1,
                                'bg-green-100 text-green-800': item.content_type === 2,
                                'bg-gray-100 text-gray-800': item.content_type === 3
                            }" class="badge badge-lg border-0 rounded-xl">
                                {{ getContentTypeLabel(item.content_type) }}
                            </div>
                            </td>
                            <div class="col-span-2 flex justify-center my-auto cursor-pointer">
                                <div @click="addToPlaylist(item.id)">
                                    <i class="fa-solid fa-plus"></i>
                                </div>
                            </div>

                        </div>
                    </div>
            </table>

            <!-- Pagination -->
            <div class="hidden lg:block">
                <CustomPagination
                    v-if="!isAssetBroadcastListLoading && props.isTeacherPlaylist == 'false'"
                    :showingFrom="assetBroadcastShowingFrom"
                    :showingTo="assetBroadcastShowingTo"
                    :totalItems="assetBroadcastTotalItems"
                    :totalPages="assetBroadcastTotalPages"
                    :currentPage="assetBroadcastCurrentPage"
                    :itemsPerPage="assetBroadcastItemsPerPage"
                    @change-page="assetBroadcastChangePage"
                />
            </div>
        </section>
    </div>
</template>