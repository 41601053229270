<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import useIsMobile from '../composables/isMobile.js';
import { useModeStore } from '../store/mode.js';
// import { useThemeStore } from '../store/theme.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { useUserStore } from '../store/user.js'; 
import ThemeButton from '../components/ThemeButton.vue';
import PageNavigator from '../components/PageNavigator.vue';

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const { isMobile } = useIsMobile();
const modeStore = useModeStore();
// const themeStore = useThemeStore();
const userStore = useUserStore();

const userData = ref(null);
const initialUserData = ref(null);
const errors = ref(null);
const selectedThumbnailFile = ref(null);

const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    selectedThumbnailFile.value = file;

    const reader = new FileReader();
    reader.onload = (e) => {
        userData.value.avatar_url = e.target.result;
    };
    reader.readAsDataURL(file);
}

const triggerFileInput = () => {
    fileInputRef.value.click();
};

const fileInputRef = ref(null);

const submitEditUserProfile = () => {
    console.log('Submit edit user profile');
    const formData = new FormData();
    formData.append('name', userData.value.name);
    if (selectedThumbnailFile.value) {
        console.log('edit userData selectedThumbnailFile.value', selectedThumbnailFile.value)
        const blob = new Blob([selectedThumbnailFile.value], { type: selectedThumbnailFile.value.type });
        formData.append('avatar', blob, selectedThumbnailFile.value.name);
    }

    formData.append('_method', 'put');
    axios.post(`api/v1/user`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        console.log('updated userdata', response);
        userData.value = response.data;
        userStore.login(response.data);
    })
    .catch((error) => {
        console.log(error);
        errors.value = error.response.data.errors;
    }); 
}

const cancelEditUserProfile = () => {
    if (initialUserData.value) {
        userData.value = deepClone(initialUserData.value);
    }
}

// const formattedUserGroups = computed(() => {
//     if (userData.value && userData.value.media_group) {
//         return userData.value.media_group.map(group => group.name).join(', ');
//     }
//     return '';
// });

//school names
const schoolNames = [
  'SEKOLAH MENENGAH KEBANGSAAN HAMID KHAN',
  'SEKOLAH MENENGAH KEBANGSAAN TROLAK SELATAN',
  'SEKOLAH MENENGAH KEBANGSAAN (FELDA) BESOUT',
  'SEKOLAH MENENGAH KEBANGSAAN SUNGAI KERUIT',
  'SEKOLAH MENENGAH KEBANGSAAN AIR KUNING',
  'SEKOLAH MENENGAH KEBANGSAAN CHENDERIANG',
  'KOLEJ TINGKATAN ENAM TAPAH',
  'SEKOLAH MENENGAH KEBANGSAAN BIDOR',
  'SEKOLAH MENENGAH SAINS TAPAH',
  'SEKOLAH MENENGAH KEBANGSAAN SERI MANJUNG',
  'SEKOLAH MENENGAH KEBANGSAAN AHMAD BOESTAMAM',
  'SEKOLAH MENENGAH KEBANGSAAN PANGKALAN TLDM',
  'SEKOLAH MENENGAH KEBANGSAAN BATU SEPULUH',
  'SEKOLAH MENENGAH KEBANGSAAN PANTAI REMIS',
  'SEKOLAH MENENGAH KEBANGSAAN SERI SAMUDERA',
  'SEKOLAH MENENGAH KEBANGSAAN CHANGKAT BERUAS',
  'SEKOLAH MENENGAH KEBANGSAAN KG. DATO\' SERI KAMARUDDIN',
  'SEKOLAH MENENGAH KEBANGSAAN RAJA CHULAN',
  'SEKOLAH MENENGAH KEBANGSAAN JALAN PASIR PUTEH',
  'SEKOLAH MENENGAH KEBANGSAAN GUNUNG RAPAT',
  'SEKOLAH MENENGAH KEBANGSAAN RAJA PERMAISURI BAINUN',
  'SEKOLAH MENENGAH KEBANGSAAN DATO\' HJ. MOHD TAIB',
  'SEKOLAH MENENGAH KEBANGSAAN SERI KELEDANG',
  'SEKOLAH MENENGAH KEBANGSAAN KG. DATO\' AHMAD SAID',
  'SEKOLAH MENENGAH KEBANGSAAN KG PASIR PUTEH',
  'SEKOLAH MENENGAH KEBANGSAAN SERI AMPANG',
  'SEKOLAH MENENGAH KEBANGSAAN JALAN TASEK',
  'SEKOLAH MENENGAH KEBANGSAAN SIMPANG PULAI'
];

const searchQuery = ref('');
const showSchoolNamesDropdown = ref(false);

const filteredSchoolNames = computed(() => {
  return schoolNames.filter(schoolName => schoolName.toLowerCase().includes(searchQuery.value.toLowerCase()));
});

const selectSchool = (schoolName) => {
  userData.value.schoolName = schoolName;
  searchQuery.value = schoolName;
  showSchoolNamesDropdown.value = false;
};

//tahap
const levels = [
  'Pra Sekolah',
  'Menengah Atas',
  'Menengah Bawah',
];

//Tingkatan/Tahun
const grades = [
    'Tahun 1',
    'Tahun 2',
    'Tahun 3',
    'Tahun 4',
    'Tahun 5',
    'Tahun 6',
    'Tingkatan 1',
    'Tingkatan 2',
    'Tingkatan 3',
    'Tingkatan 4',
    'Tingkatan 5',
    'Tingkatan 6',
]

//stream
const streams = [
    'Science Stream',
    'Arts Stream',
    'Technical Stream',
    'Vocational Stream',
    'Mixed Stream',
]

const activePageStore = useActivePageStore();
onMounted(() => {
    activePageStore.setSelectedActivePage(9)
    console.log('userStore.user', userStore.user)
    //userData.value = userStore.user
    if (userStore.user) {
        initialUserData.value = deepClone(userStore.user);
        userData.value = userStore.user
        if (userStore.user.avatar_url) {
            userData.value.avatar_url = userStore.user.avatar_url
        }
    }

});

</script>

<template>
    <div class="min-h-screen">
        <page-navigator from="Laman Utama" to="Tetapan" />
        <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto py-4 px-[30px]">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">{{ $t('setting.profileSetting') }}</h2>
        </div>
        <section 
            v-if="userData"
            class="flex flex-col gap-8 rounded-xl p-[30px]"
        > 
            <div>
                <!-- <div class="flex gap-2">
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('setting.profileSetting') }}</h2>
                </div> -->
            </div>
            <div class="md:grid md:grid-cols-12 gap-4">
                <div id="settings-left" class="col-span-2 ml-0">
                    <div class="rounded-xl relative min-h-40 w-[200px] h-[200px] items-center justify-center mx-auto">
                        <!-- avatar image -->
                        <img v-if="userData.avatar_url" :src="userData.avatar_url" alt="avatar" class="w-full h-full object-cover rounded-full">
                        
                        <!-- placeholder when no avatar -->
                        <div v-if="!userData.avatar_url"
                        :class="modeStore.selectedMode === 'dark' ? 'bg-black' : 'bg-gray-200' "
                        class="w-full h-full rounded-full flex flex-col items-center justify-center border-2 border-dashed border-gray-400">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.87533 10.9167C11.0259 10.9167 11.9587 9.98393 11.9587 8.83333C11.9587 7.68274 11.0259 6.75 9.87533 6.75C8.72473 6.75 7.79199 7.68274 7.79199 8.83333C7.79199 9.98393 8.72473 10.9167 9.87533 10.9167Z" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.0413 2.58594H9.87467C4.66634 2.58594 2.58301 4.66927 2.58301 9.8776V16.1276C2.58301 21.3359 4.66634 23.4193 9.87467 23.4193H16.1247C21.333 23.4193 23.4163 21.3359 23.4163 16.1276V10.9193" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.9062 5.71094H22.6354" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M19.7705 8.57292V2.84375" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M3.28125 20.2422L8.41667 16.7943C9.23958 16.2422 10.4271 16.3047 11.1667 16.9401L11.5104 17.2422C12.3229 17.9401 13.6354 17.9401 14.4479 17.2422L18.7812 13.5234C19.5937 12.8255 20.9063 12.8255 21.7188 13.5234L23.4167 14.9818" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span 
                            :class="modeStore.selectedMode === 'dark' ? 'text-white' : 'text-black'"
                            class="text-lg">Muat naik</span>
                            <span class="text-gray-400 text-sm">atau seret & lepas gambar</span>
                        </div>
                        
                        <input type="file" ref="fileInputRef" class="hidden" @change="handleFileInputChange">
                        
                        <div @click="triggerFileInput" class="absolute inset-0 rounded-full cursor-pointer flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.87533 10.9167C11.0259 10.9167 11.9587 9.98393 11.9587 8.83333C11.9587 7.68274 11.0259 6.75 9.87533 6.75C8.72473 6.75 7.79199 7.68274 7.79199 8.83333C7.79199 9.98393 8.72473 10.9167 9.87533 10.9167Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14.0413 2.58594H9.87467C4.66634 2.58594 2.58301 4.66927 2.58301 9.8776V16.1276C2.58301 21.3359 4.66634 23.4193 9.87467 23.4193H16.1247C21.333 23.4193 23.4163 21.3359 23.4163 16.1276V10.9193" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.9062 5.71094H22.6354" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M19.7705 8.57292V2.84375" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M3.28125 20.2422L8.41667 16.7943C9.23958 16.2422 10.4271 16.3047 11.1667 16.9401L11.5104 17.2422C12.3229 17.9401 13.6354 17.9401 14.4479 17.2422L18.7812 13.5234C19.5937 12.8255 20.9063 12.8255 21.7188 13.5234L23.4167 14.9818" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <!-- <div
                    id="settings-right"
                    :class="{'bg-white bg-opacity-40 text-black': modeStore.selectedMode === 'light', 'bg-black bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
                    class="col-span-10 text-left rounded-xl p-4">
                    <p class="text-xl pb-6">{{ $t('setting.personalInformation') }}</p>
                    <label for="name" class="block mb-1">{{ $t('setting.fullName') }}</label>
                    <input v-model="userData.name" type="text" id="name" placeholder="" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent block w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:border-blue-500">
                    <div class="grid  md:gap-2 mb-4 md:mb-0">
                        <div>
                            <label for="level" class="block mb-1">{{ $t('setting.grade') }}</label>
                            <textarea 
                                disabled 
                                :value="formattedUserGroups" 
                                id="level" 
                                placeholder="" 
                                :class="modeStore.selectedMode === 'dark'? 'border-gray-600 bg-gray-900 text-gray-500': 'bg-gray-200 text-gray-400'" 
                                class="block w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:border-blue-500"
                                rows="3"
                            ></textarea>
                        </div>
                        <div class="hidden">
                            <label for="gender" class="block mb-1">{{ $t('setting.gender') }}</label>
                            <select v-model="userData.gender" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2">
                                <option value="male">{{ $t('setting.male') }}</option>
                                <option value="female">{{ $t('setting.female') }}</option>
                            </select>
                        </div>
                    </div>
                    <label for="email" class="block mb-1">{{ $t('setting.emailAddress') }}</label>
                    <input disabled v-model="userData.email" type="email" id="email" placeholder=""
                         :class="modeStore.selectedMode === 'dark'? 'border-gray-600 bg-gray-900 text-gray-500': 'bg-gray-200 text-gray-400'" 
                         class="block w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:border-blue-500">
                    
                </div> -->
                <div
                    id="settings-right"
                    :class="{'bg-white bg-opacity-40 text-black': modeStore.selectedMode === 'light', 'bg-black bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
                    class="col-span-10 text-left rounded-xl p-4">
                    <p class="text-xl pb-6">{{ $t('setting.personalInformation') }}</p>
                    <div class="grid md:grid-cols-2 gap-4 mb-2">
                        <div>
                            <label for="name" class="block mb-1">{{ $t('setting.fullName') }}</label>
                            <input v-model="userData.name" type="text" id="name" placeholder="" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent block w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500">
                        </div>
                        <div class="relative">
                            <label for="schoolName" class="block mb-1">{{ $t('setting.schoolName') }}</label>
                            
                            <!-- Input field for searching -->
                            <input 
                            v-model="searchQuery"
                            @input="showSchoolNamesDropdown = true"
                            :class="modeStore.selectedMode === 'dark' ? 'border-gray-600' : ''" 
                            class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2 text-sm md:text-base"
                            placeholder=""
                            />

                            <!-- Dropdown for filtered school names -->
                            <ul v-if="showSchoolNamesDropdown && filteredSchoolNames.length" class="absolute top-18 border rounded-md bg-white dark:bg-gray-800 mt-1 max-h-60 overflow-auto">
                                <li 
                                    v-for="(schoolName, index) in filteredSchoolNames" 
                                    :key="index" 
                                    @click="selectSchool(schoolName)" 
                                    class="px-4 py-2 cursor-pointer hover:bg-blue-500 hover:text-white dark:hover:bg-gray-600"
                                >
                                    {{ schoolName }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="grid md:grid-cols-2 gap-4 mb-2">
                        <div>
                            <label for="level" class="block mb-1">{{ $t('setting.level') }}</label>
                            <select v-model="userData.level" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2 text-sm md:text-base">
                                <option v-for="(level, index) in levels" :key="index" :value="level">{{ level }}</option>
                             </select>
                        </div>
                        <div>
                            <label for="grade" class="block mb-1">{{ $t('setting.grade') }}</label>
                            <select v-model="userData.grade" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2 text-sm md:text-base">
                                <option v-for="(grade, index) in grades" :key="index" :value="grade">{{ grade }}</option>
                             </select>
                        </div>
                    </div>
                    <div class="grid md:grid-cols-2 gap-4 mb-2">
                        <div>
                            <label for="stream" class="block mb-1">{{ $t('setting.stream') }}<span class="text-red-600">*</span></label>
                            <select v-model="userData.stream" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2 text-sm md:text-base">
                                <option v-for="(stream, index) in streams" :key="index" :value="stream">{{ stream }}</option>
                             </select>
                        </div>
                        <div>
                            <label for="gender" class="block mb-1">{{ $t('setting.gender') }}<span class="text-red-600">*</span></label>
                            <select v-model="userData.gender" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2">
                                <option value="male">{{ $t('setting.male') }}</option>
                                <option value="female">{{ $t('setting.female') }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label for="email" class="block mb-1">{{ $t('setting.emailAddress') }}</label>
                        <input disabled v-model="userData.email" type="email" id="email" placeholder=""
                            :class="modeStore.selectedMode === 'dark'? 'border-gray-600 bg-gray-900 text-gray-500': 'bg-gray-200 text-gray-400'" 
                            class="block w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:border-blue-500">
                    </div>
                </div>
            </div>

            <div class="flex gap-2 justify-end py-4">
                <ThemeButton
                    variant="quaternary"
                    size="medium"
                    @click="cancelEditUserProfile"
                >
                    {{ $t('general.cancel') }}
                </ThemeButton>
                
                <ThemeButton
                    variant="primary"
                    size="medium"
                    @click="submitEditUserProfile"
                >
                    {{ $t('general.save') }}
                </ThemeButton>
            </div>
        </section>
    </div>
</template>