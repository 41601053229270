<script setup>
// import { ref, onMounted } from 'vue'
import axios from 'axios';
import { onMounted, computed, ref } from 'vue'
import { useModeStore } from '../store/mode.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { useTranslation } from "i18next-vue";
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { getModeStrokeColor, getContentTypeLabel } from '../utils/commonFunctions.js';
const { t } = useTranslation();

import DocumentsCard from '../components/DocumentsCard.vue'
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';
import AddOrCreatePlaylistTopBar from '../components/AddOrCreatePlaylistTopBar.vue';
import AddToPlaylistDropdown from '../components/AddToPlaylistDropdown.vue';

const modeStore = useModeStore();
const activePageStore = useActivePageStore();
const modeStrokeColor = getModeStrokeColor();
const addToPlaylistStore = useAddToPlaylistStore();
const user = ref({});
const isUserDetailsLoading = ref(true);
const isGridDisplayPattern = ref(true);

const justwatcheds = [
{
        id: 1,
        title: "4.9 Teknik Peryoalan Keluarga Asas Saya",
        content_type: 2,
        has_added_to_workspace: true,
        thumbnails: [
            { id: 1, url: '../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png'}
        ],
        tags: [
            { id: 64, name: 'proses hidup haiwan' },
        ],
        groups:[
            { "id": 4, "name": "Tahun 4" }
        ],
        subjects:[
            { "id": 1, "name": "Sains" }
        ]
    },
    {
        id: 2,
        title: "4.10 Teknik Peryoalan Keluarga Asas Saya",
        content_type: 2,
        has_added_to_workspace: false,
        thumbnails: [
            { id: 1, url: '../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png'}
        ],
        groups:[
            { "id": 4, "name": "Tahun 4" }
        ],
        subjects:[
            { "id": 1, "name": "Sains" }
        ],
    },
    {
        id: 3,
        title: "4.11 Teknik Peryoalan Keluarga Asas Saya",
        content_type: 2,
        has_added_to_workspace: false,
        thumbnails: [
            { id: 1, url: '../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png'}
        ],
        groups:[
            { "id": 4, "name": "Tahun 4" }
        ],
        subjects:[
            { "id": 1, "name": "Sains" }
        ]
    },
    {
        id: 4,
        title: "4.12 Teknik Peryoalan Keluarga Asas Saya",
        content_type: 2,
        has_added_to_workspace: false,
        thumbnails: [
            { id: 1, url: '../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png'}
        ],
        groups:[
            { "id": 4, "name": "Tahun 4" }
        ],
        subjects:[
            { "id": 1, "name": "Sains" }
        ]
    },
]

const textbooks = [
{
        id: 1,
        title: "4.9 Teknik Peryoalan Keluarga Asas Saya",
        content_type: 2,
        has_added_to_workspace: true,
        thumbnails: [
            { id: 1, url: '../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png'}
        ],
        tags: [
            { id: 64, name: 'proses hidup haiwan' },
        ],
        groups:[
            { "id": 4, "name": "Tahun 4" }
        ],
        subjects:[
            { "id": 1, "name": "Sains" }
        ]
    },
    {
        id: 2,
        title: "4.10 Teknik Peryoalan Keluarga Asas Saya",
        content_type: 2,
        has_added_to_workspace: false,
        thumbnails: [
            { id: 1, url: '../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png'}
        ],
        groups:[
            { "id": 4, "name": "Tahun 4" }
        ],
        subjects:[
            { "id": 1, "name": "Sains" }
        ],
    },
    {
        id: 3,
        title: "4.11 Teknik Peryoalan Keluarga Asas Saya",
        content_type: 2,
        has_added_to_workspace: false,
        thumbnails: [
            { id: 1, url: '../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png'}
        ],
        groups:[
            { "id": 4, "name": "Tahun 4" }
        ],
        subjects:[
            { "id": 1, "name": "Sains" }
        ]
    },
    {
        id: 4,
        title: "4.12 Teknik Peryoalan Keluarga Asas Saya",
        content_type: 2,
        has_added_to_workspace: false,
        thumbnails: [
            { id: 1, url: '../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png'}
        ],
        groups:[
            { "id": 4, "name": "Tahun 4" }
        ],
        subjects:[
            { "id": 1, "name": "Sains" }
        ]
    },
    {
        id: 5,
        title: "4.13 Teknik Peryoalan Keluarga Asas Saya",
        content_type: 2,
        has_added_to_workspace: false,
        thumbnails: [
            { id: 1, url: '../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png'}
        ],
        groups:[
            { "id": 4, "name": "Tahun 4" }
        ],
        subjects:[
            { "id": 1, "name": "Sains" }
        ]
    },
]

const changeDisplayPattern = () => {
    isGridDisplayPattern.value = !isGridDisplayPattern.value;
};

function callUserApi() {
    // get api/v1/user
    isUserDetailsLoading.value = true;
    axios.get('api/v1/user').then(response => {
        user.value = response.data;
        console.log('loaded user data', user.value);

        console.log('user group', user.value.media_group[0].name);

    }).catch(error => {
        console.error('Failed to fetch user data:', error);
    }).finally(() => {
        isUserDetailsLoading.value = false
    });
}

const textbookBannerImage = computed(() => {
    //return require('../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png')
    return require('../assets/Images/Views/TextbookPage/banner_image_buku_teks.png')
});

onMounted(() => {
    callUserApi(),
    activePageStore.setSelectedActivePage(8)
});
</script>

<template>
    <div>
        <AddOrCreatePlaylistTopBar v-show="addToPlaylistStore.showAddOrCreatePlaylistTopBar" />
        <AddToPlaylistDropdown v-show="addToPlaylistStore.showAddToPlaylistDropdown" />
        <CreateEditPlaylistModal v-show="addToPlaylistStore.showCreateOrEditPlaylistModal" :isAtPlaylistPages="false"/>
        <!-- banner -->
        <div v-show="isUserDetailsLoading" class="flex items-center justify-center w-full gap-2 p-6">
            <div>{{ $t('general.loading')}}</div>
            <span class="loading loading-bars loading-sm"></span>
        </div>

        <div v-show="!isUserDetailsLoading" id="step-4" class="relative rounded-xl p-[30px] my-4 h-[250px] md:h-[350px] md:dark-mode-dashboard-banner" :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}">
            <div class="grid md:grid-cols-4 h-full">
                <!--left side-->
                <div class="md:col-span-2 md:my-auto z-20">
                    <div class="">
                        <h2 class="text-3xl">{{ t('textbookPage.welcome')}} </h2>
                        <div class="text-6xl font-bold py-1" v-if="user && user.media_group && user.media_group.length > 0">Pelajar {{ user.media_group[0].name }}</div>
                        <div class="text-base uppercase flex py-2" v-if="user && user.media_group && user.media_group.length > 0">
                            <p class="pr-2">{{ user.media_group[0].name }}</p>
                            <p class="pl-2 border-l" v-if="user.school"> {{ user.school }}</p>
                        </div> 
                    </div>
                </div>
                <!--right side-->
                <div class="col-span-1">
                    <div class="hidden md:block absolute top-0 right-0 h-full " >
                        <div class="relative">
                            <img :src="textbookBannerImage" alt="welcome user card background" class="object-fill rounded-xl max-h-[350px]"> 
                        </div>
                    </div>    
                </div>
            </div>
        </div>

        <section 
            class="flex flex-col gap-8 rounded-xl p-[30px] bg-transparent"
            :class="{'bg-white': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
        >
            <div class="flex justify-between justify-items-center my-auto">
                <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">Baru Ditonton</h2>
            </div>

            <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <DocumentsCard
                    class="md:min-w-0 lg:w-full"
                    v-for="justwatched in justwatcheds"
                    :key="justwatched.id"
                    :id="justwatched.id"
                    :image="textbookBannerImage"
                    :topic="justwatched.title"
                    :groups="justwatched.groups ? justwatched.groups : []"
                    :subjects="justwatched.subjects ? justwatched.subjects : []"
                    :tags="justwatched.tags"
                    :isAddedToWorkspace="null"
                    :contentType="justwatched.content_type"  
                />
            </div>
            
        </section>

        <section 
            class="flex flex-col gap-8 rounded-xl p-[30px] bg-transparent"
            :class="{'bg-white': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
        >
            <div class="flex justify-between justify-items-center my-auto">
                <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">Koleksi Interaktif Buku Teks</h2>
                <div class="my-auto h-10">
                    <div class="flex gap-1 justify-between h-10 my-auto">
                        <div @click="changeDisplayPattern()" class="hidden md:block w-8 my-auto cursor-pointer">
                            <!-- <img v-if="isGridDisplayPattern" class="my-auto" :src="modeStore.selectedMode === 'dark' ? whiteHamburgerIcon: blackHamburgerIcon" alt="display in list icon" /> -->
                            <svg v-if="isGridDisplayPattern" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.75 8.75H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                            <path d="M3.75 15H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                            <path d="M3.75 21.25H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                            </svg>
                            <!-- <img v-else class="my-auto" :src="modeStore.selectedMode === 'dark' ? whiteDisplayInGridIcon : blackDisplayInGridIcon" alt="display in grid icon" /> -->
                            <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M27.5 10.65V4.975C27.5 3.2125 26.7 2.5 24.7125 2.5H19.6625C17.675 2.5 16.875 3.2125 16.875 4.975V10.6375C16.875 12.4125 17.675 13.1125 19.6625 13.1125H24.7125C26.7 13.125 27.5 12.4125 27.5 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M27.5 24.7125V19.6625C27.5 17.675 26.7 16.875 24.7125 16.875H19.6625C17.675 16.875 16.875 17.675 16.875 19.6625V24.7125C16.875 26.7 17.675 27.5 19.6625 27.5H24.7125C26.7 27.5 27.5 26.7 27.5 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.125 10.65V4.975C13.125 3.2125 12.325 2.5 10.3375 2.5H5.2875C3.3 2.5 2.5 3.2125 2.5 4.975V10.6375C2.5 12.4125 3.3 13.1125 5.2875 13.1125H10.3375C12.325 13.125 13.125 12.4125 13.125 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M13.125 24.7125V19.6625C13.125 17.675 12.325 16.875 10.3375 16.875H5.2875C3.3 16.875 2.5 17.675 2.5 19.6625V24.7125C2.5 26.7 3.3 27.5 5.2875 27.5H10.3375C12.325 27.5 13.125 26.7 13.125 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <!--grid layout view -->
            <div v-if="isGridDisplayPattern" class="grid grid-cols-2 md:grid-cols-4 gap-4">
                <DocumentsCard
                    class="md:min-w-0 lg:w-full"
                    v-for="textbook in textbooks"
                    :key="textbook.id"
                    :id="textbook.id"
                    :image="textbookBannerImage"
                    :topic="textbook.title"
                    :groups="textbook.groups ? textbook.groups : []"
                    :subjects="textbook.subjects ? textbook.subjects : []"
                    :tags="textbook.tags"
                    :isAddedToWorkspace="null"
                    :contentType="textbook.content_type"  
                />
            </div>

            <!--rows layout view -->
            <div v-else :class="{'text-black bg-white': modeStore.selectedMode === 'light', 'bg-black text-white': modeStore.selectedMode === 'dark'}" class="bg-opacity-30 rounded-xl py-[30px]" >
                <div class="grid grid-cols-12 py-2 text-base font-semibold px-6">
                    <div class="col-span-1 invisible">checkbox</div>
                    <div class="col-span-6 text-center">{{ $t('documentPage.title')}}</div>
                    <div class="col-span-3 text-center">{{ $t('documentPage.fileType')}}</div>
                </div>

                <!-- <div v-show="isLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div> -->

                <div v-for="textbook in textbooks" :key="textbook.id" :class="{'hover:bg-white hover:bg-opacity-10': modeStore.selectedMode === 'dark', 'hover:bg-gray-200 hover:bg-opacity-20':modeStore.selectedMode === 'light'}" class="grid grid-cols-12 py-4 px-6">
                    <div class="col-span-1 text-center my-auto">
                        <input ref="checkbox" @click="showBarAndUpdateSelectedFile(textbook.id)" type="checkbox" :id="'checkbox-' + textbook.id" class="file-checkbox h-5 w-5"/>
                    </div>
                    <div class="col-span-6 text-center my-auto">
                        <div class="flex gap-2 cursor-pointer">
                            <img @click.prevent="goToDocument(textbook.id)" :src="textbookBannerImage" alt="course img" class="max-h-16 rounded-md">
                            <div class="my-auto">
                                <div @click.prevent="goToDocument(textbook.id)" class="my-auto font-semibold">{{ textbook.title }}</div>
                                <div class="flex overflow-hidden">
                                    <div v-if="textbook.groups && textbook.groups.length > 0">
                                        <div  v-for="(group, index) in textbook.groups" :key="group.id" class="flex overflow-hidden">
                                            <div class="cursor-pointer" @click="goToDocumentPageWithCourseLvlFilter(group.id)">{{ group.name }}</div><span v-if="index < textbook.groups.length - 1">, </span>
                                        </div>
                                    </div>
                                    <div v-if="textbook.subjects && textbook.subjects.length > 0">
                                        <span class="px-1"> | </span>
                                    </div>
                                    <div v-if="textbook.subjects && textbook.subjects.length > 0">
                                        <div v-for="(subject, index) in textbook.subjects" :key="subject.id" class="flex overflow-hidden">
                                            <div class="cursor-pointer" @click="goToDocumentPageWithSubjectFilter(subject.id)">{{ subject.name }}</div><span v-if="index < textbook.subjects.length - 1">, </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="text-sm">{{ document.courseLevel }}</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-span-3 text-center my-auto">
                        <div             
                            :class="{
                                'bg-[#FEF3C7] text-[#92400E]': textbook.content_type === 1,
                                'bg-green-100 text-green-800': textbook.content_type === 2,
                                'bg-gray-100 text-gray-800': textbook.content_type === 3
                            }" class="badge badge-lg border-0 rounded-xl">
                                {{ getContentTypeLabel(textbook.content_type) }}
                            </div>
                    </div>
                </div>
            </div>
            
        </section>
    </div>
</template>