<script setup>
import axios from 'axios';
import { ref, onMounted, watch, defineEmits, computed } from 'vue';
import { useModeStore } from '../store/mode.js';
import useIsMobile from '../composables/isMobile.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useThemeStore } from '../store/theme.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { getStrokeColor, getPaginationBackgroundColor } from '../utils/commonFunctions.js';
import { useUserStore } from '../store/user.js'; 
import { useTranslation } from "i18next-vue";
const { t } = useTranslation();

// onboarding
import { VOnboardingWrapper, VOnboardingStep, useVOnboarding } from 'v-onboarding';

// Import components
import PlaylistCard from '../components/PlaylistCard.vue'
import PlaylistCardSkeletonLoader from '../components/PlaylistCardSkeletonLoader.vue'
import TeachersCard from '../components/TeachersCard.vue'
import DocumentsCard from '../components/DocumentsCard.vue'
import DocumentSkeletonCard from '@/components/DocumentSkeletonCard.vue';
import RecentlyWatched from '../components/RecentlyWatched.vue'
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';
import AddOrCreatePlaylistTopBar from '../components/AddOrCreatePlaylistTopBar.vue';
import AddToPlaylistDropdown from '../components/AddToPlaylistDropdown.vue';
import NotificationTopBar from '../components/NotificationTopBar.vue';

const modeStore = useModeStore();
const userStore = useUserStore();
const themeStore = useThemeStore();
const { isMobile } = useIsMobile();
const addToPlaylistStore = useAddToPlaylistStore();
const notificationTopBarStore = useNotificationTopBarStore();
const strokeColor = getStrokeColor();
// const modeStrokeColor = getModeStrokeColor();
const bgColor = getPaginationBackgroundColor();
const user = ref({});
const numOfUnreadNotification = ref(0);
const playlistData = ref([]);
const assetBroadcastsData = ref([]);
const teachersListData = ref([]);
const recentlyWatchedListData = ref([]);
const justForYouListData = ref([]);
const isUserDetailsLoading = ref(true);
const isAssetBroadcastsLoading = ref(true);
const isPlaylistLoading = ref(true);
const isTeachersListDataLoading = ref(true);
const isRecentlyWatchedListDataLoading = ref(true);
const isJustForYouListDataLoading = ref(true);
const showOnboardingOption = ref(false);
const userStats = computed(() => userStore.stats);
const emit = defineEmits(['toggle-lang-theme-signout']);

const availableUserGroups = ref([]);
const savingSelectedUserGroup = ref(null);
const selectedUserGroup = ref(null);

const emitToggleLangThemeSignOut = () => {
    emit('toggle-lang-theme-signout');
}

const markUserAsOnboarded = () => {
    console.log('Marked user as onboarded')
    // post api/v1/user/onboarded
    axios.post('api/v1/user/onboarded').then(response => {
        userStore.user = response.data;
    }).catch(error => {
        console.error('Failed to mark user as onboarded:', error);
    });
}

// vue onboarding
const wrapper = ref(null)
const { start, finish } = useVOnboarding(wrapper)
const steps = [
    {
        index: 0,
        attachTo: { element: '#step-1' },
        content: {
            title: t('onboarding.sideMenu'),
            description: t('onboarding.skipTour'),
        },
        options: {
            popper: {
                placement: 'right',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        },
                    }
                ],
            },
        }
    },
    {
        index: 1,
        attachTo: { element: '#step-2' },
        content: {
            title: t('onboarding.topHeader'),
            description: t('onboarding.skipTour'),
        },
        options: {
            popper: {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        },
                    }
                ],
            }
        }
    },
    {
        index: 2,
        attachTo: { element: '#step-3' },
        content: {
            title: t('onboarding.minimizePlaylist'),
            description: t('onboarding.skipTour'),
        },
        options: {
            popper: {
                placement: 'top',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        },
                    }
                ],
            },
        }
    },
    {
        index: 3,
        attachTo: { element: '#step-4' },
        content: {
            title: t('onboarding.banner'),
            description: t('onboarding.skipTour'),
        },
        options: {
            popper: {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        },
                    }
                ],
            },
        }
    },
    {
        index: 4,
        attachTo: { element: '#step-5' },
        content: {
            title: t('onboarding.playlist'),
            description: t('onboarding.skipTour'),
        },
        options: {
            popper: {
                placement: 'top',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        },
                    }
                ],
            },
        }
    },
    {
        index: 5,
        attachTo: { element: '#step-6' },
        content: {
            title: t('onboarding.langTheme'),
            description: t('onboarding.skipTour'),
        },
        on: {
            beforeStep: emitToggleLangThemeSignOut,
            afterStep: emitToggleLangThemeSignOut,
        },
        options: {
            popper: {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        },
                    }
                ],
            },
        }
    },
    {
        index: 6,
        attachTo: { element: '#step-7' },
        content: {
            title: t('onboarding.workspace'),
            description: t('onboarding.skipTour'),
        },
        on: {
            afterStep: markUserAsOnboarded,
        },
        options: {
            popper: {
                placement: 'bottom',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10]
                        },
                    }
                ],
            },
        }
    }
]

const stepIndicatorSvg = computed(() => {
  return (currentStep) => {
    const totalSteps = steps.length;
    let svgContent = '';
    
    for (let i = 0; i < totalSteps; i++) {
      const isCurrentStep = i === currentStep;
      const x = i * 14; // Increased spacing between indicators
      const fill = isCurrentStep ? '#F97316' : '#D1D5DB';
      const width = isCurrentStep ? 20 : 5;
      
      svgContent += `<rect x="${x}" y="0" width="${width}" height="5" rx="2.5" fill="${fill}"/>`;
    }
    
    const svgWidth = (totalSteps - 1) * 14 + 20; // Adjust total width based on number of steps
    
    return `<svg width="${svgWidth}" height="5" viewBox="0 0 ${svgWidth} 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              ${svgContent}
            </svg>`;
  };
});

// import AllSearchResult from '../components/AllSearchResult.vue'

// const showAllSearchResult = ref(false); 

onMounted(() => {
    addToPlaylistStore.closeAddOrCreatePlaylistTopBar();
    callUserApi(),
    callNotificationApi(),
    callPlaylistApi(),
    callAssetBroadcastApi(),
    callTeachersListApi(),
    callPlaylistProgressApi(),
    callJustForYouListApi()
});

watch(
  [
    isUserDetailsLoading,
    isAssetBroadcastsLoading,
    isPlaylistLoading,
    isTeachersListDataLoading,
    isRecentlyWatchedListDataLoading,
    isJustForYouListDataLoading
  ],
  (newValues) => {
      if ((newValues.every((value) => value === false)) && !userStore.user.completed_onboarding ) {
        showOnboardingOption.value = true; // Popup the onboarding option when all loading variables are false AND user has not completed onboarding
      }
  }
);

const closeOnboardingPopup = () => {
    showOnboardingOption.value = false;
}

const startTour = () => {
    showOnboardingOption.value = false;
    start();
}

const endTour = () => {
    finish();
}

function callUserApi() {
    // get api/v1/user
    isUserDetailsLoading.value = true;
    axios.get('api/v1/user').then(response => {
        user.value = response.data;
        console.log('loaded user data', user.value);

        if (!user.value.media_group || user.value.media_group?.length <= 0) { 
            console.log('Get user groups');
            // didnt chosen any user group, load user group api
            getAvailableUserGroups();
        }
    }).catch(error => {
        console.error('Failed to fetch user data:', error);
    }).finally(() => {
        isUserDetailsLoading.value = false
    });

    // user stats for welcome banner
    axios.get('api/v1/user/stats').then(response => {
        userStore.setUserStats(response.data.stats);
    }).catch(error => {
        console.error('Failed to fetch user stats data:', error);
    });
}

function saveUserGroupOfUser() {
    savingSelectedUserGroup.value = true;
    axios.post('api/v1/user-groups', {
        user_group_id: selectedUserGroup.value
    })
    .then(response => {
        // override userStore.user data with response.data
        userStore.user = response.data;
        savingSelectedUserGroup.value = false;
    })
    .catch(error => {
        console.error('Failed to save user group of user:', error);
        savingSelectedUserGroup.value = false;
    });
}

function getAvailableUserGroups() {
    axios.get('api/v1/user-groups').then(response => {
        if (typeof response.data.user_groups !== 'undefined') {
            availableUserGroups.value = response.data.user_groups;
        } else {
            console.log('No user groups available')
        }
    })
}

function callNotificationApi() {
    // get api/v1/notifications
    axios.get('api/v1/notifications').then(response => {
        numOfUnreadNotification.value = response.data.total;
    }).catch(error => {
        console.error('Failed to fetch notifications data:', error);
    });
}

const amtOfPlaylist = ref(0);
function callPlaylistApi() {
    // get api/v1/playlists
    isPlaylistLoading.value = true;
    axios.get('api/v1/playlists').then(response => {
        playlistData.value = response.data.data;
        amtOfPlaylist.value = response.data.total;
    }).catch(error => {
        console.error('Failed to fetch playlists data:', error);
    }).finally(() => {
        isPlaylistLoading.value = false
    });
}

const assetBroadcastTotal = ref(0);
function callAssetBroadcastApi() {
    // get api/v1/asset-broadcasts
    isAssetBroadcastsLoading.value = true;
    axios.get('api/v1/asset-broadcasts').then(response => {
        assetBroadcastsData.value = response.data.data;
        assetBroadcastTotal.value = response.data.total;
    }).catch(error => {
        console.error('Failed to fetch asset broadcasts data:', error);
    }).finally(() => {
        isAssetBroadcastsLoading.value = false
    });
}

function callTeachersListApi() {
    // get api/v1/users (role is cikgu)
    isTeachersListDataLoading.value = true;
    axios.get('api/v1/users?role=cikgu').then(response => {
        teachersListData.value = response.data.data;
    }).catch(error => {
        console.error('Failed to fetch teachers data:', error);
    }).finally(() => {
        isTeachersListDataLoading.value = false
    });
}

function callPlaylistProgressApi() {
    // get api/v1/playlists/my_progresses
    isRecentlyWatchedListDataLoading.value = true;
    axios.get('api/v1/playlists/my_progresses').then(response => {
        recentlyWatchedListData.value = response.data.data;
    }).catch(error => {
        console.error('Failed to fetch recently watched data:', error);
    }).finally(() => {
        isRecentlyWatchedListDataLoading.value = false
    });
}

function callJustForYouListApi() {
    isJustForYouListDataLoading.value = true;
    axios.get('api/v1/asset-broadcasts/user-related').then(response => {
    //   justForYouListData.value = response.data;
        if (response.data) {
            if (Array.isArray(response.data.data)) {
                justForYouListData.value = response.data.data;
            } else {
                justForYouListData.value = []; 
            }
        } else {
            justForYouListData.value = []; 
        }
    }).catch(error => {
      console.error('Failed to fetch recently watched data:', error);
    }).finally(() => {
      isJustForYouListDataLoading.value = false
    });
}

const dashboardBannerImage = computed(() => {
    //const theme = themeStore.selectedTheme;
    return require('../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png')
    // switch (theme) {
    //     case 'green':
    //       return require('../assets/Images/Views/DashboardPage/dashboardbanner_green.png');
    //     case 'orange':
    //       return require('../assets/Images/Views/DashboardPage/dashboardbanner_orange.png');
    //     case 'blue':
    //       return require('../assets/Images/Views/DashboardPage/dashboardbanner_blue.png');
    //     case 'purple':
    //       return require('../assets/Images/Views/DashboardPage/dashboardbanner_purple.png');
    //     case 'darkpink':
    //       return require('../assets/Images/Views/DashboardPage/dashboardbanner_darkpink.png');
    //     default:
    //       return require('../assets/Images/Views/DashboardPage/dashboardbanner_green.png');
    //   }
});
</script>

<template>
    <div>
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" />
        <AddOrCreatePlaylistTopBar v-show="addToPlaylistStore.showAddOrCreatePlaylistTopBar" />
        <AddToPlaylistDropdown v-show="addToPlaylistStore.showAddToPlaylistDropdown" />
        <CreateEditPlaylistModal v-show="addToPlaylistStore.showCreateOrEditPlaylistModal" :isAtPlaylistPages="false" />
        <!-- Onboarding Option PopUp -->
        <div v-show="showOnboardingOption" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm z-[100] overflow-hidden text-black">
            <!-- if user has not chosen any user group, show the onboarding option popup -->
            <div v-if="userStore.user && userStore.user.media_group?.length === 0" class="flex flex-col w-[30rem] bg-[#272423] text-white shadow sm:rounded-lg p-6 gap-5">
                <div class="flex flex-row items-center justify-between">
                    <p class="text-xl leading-9 front-medium">{{ $t('onboarding.chooseAUserGroup')}} <span class="text-red-500">*</span></p>
                </div>
                <select name="user-group" id="user-group" 
                    v-model="selectedUserGroup"
                    class="bg-transparent border rounded-md px-4 py-2 text-sm">
                    <option v-for="(userGroup, id) in availableUserGroups" :key="id" :value="id">{{ userGroup }}</option>
                </select>
                <button @click="saveUserGroupOfUser"
                    :disabled="selectedUserGroup === null || savingSelectedUserGroup"
                    :class="{'opacity-50': selectedUserGroup === null || savingSelectedUserGroup}"
                    :data-theme="themeStore.selectedTheme" class="py-2 px-4 rounded-lg text-white">
                    <span class="loading loading-spinner" v-if="savingSelectedUserGroup"></span>
                    {{ $t('general.save')}}
                </button>
            </div>
            <div v-else class="flex flex-col w-[30rem] bg-[#272423] text-white shadow sm:rounded-lg p-6 gap-5">
                <div class="flex flex-row items-center justify-between">
                    <p class="text-2xl leading-9 front-medium">{{ $t('onboarding.welcomeToDelimaFlix')}}</p>
                    <button @click="closeOnboardingPopup">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.866117 0.738675C1.35427 0.25052 2.14573 0.25052 2.63388 0.738675L8 6.10479L13.3661 0.738675C13.8543 0.25052 14.6457 0.25052 15.1339 0.738675C15.622 1.22683 15.622 2.01829 15.1339 2.50644L9.76777 7.87256L15.1339 13.2387C15.622 13.7268 15.622 14.5183 15.1339 15.0064C14.6457 15.4946 13.8543 15.4946 13.3661 15.0064L8 9.64033L2.63388 15.0064C2.14573 15.4946 1.35427 15.4946 0.866117 15.0064C0.377961 14.5183 0.377961 13.7268 0.866117 13.2387L6.23223 7.87256L0.866117 2.50644C0.377961 2.01829 0.377961 1.22683 0.866117 0.738675Z" 
                            fill="white"/>
                        </svg>
                    </button>
                </div>
                <!-- {{ $t('onboarding.chosen_user_group', {user_group: userStore.user.media_group[0].name}) }} -->

                <div><p>{{ $t('onboarding.message')}}</p></div>
                <div class="text-center">
                    <button
                        @click="startTour" type="button"
                        class="inline-flex items-center justify-center rounded-md border border-transparent px-4 py-3 font-medium text-white shadow-sm focus:outline-none sm:text-sm w-full"
                        :class="bgColor"
                    >
                        {{ $t('onboarding.startOnboarding')}}
                    </button>
                </div>
            </div>
        </div>

        <!-- dashboard welcome user card -->
        <div v-show="isUserDetailsLoading" class="flex items-center justify-center w-full gap-2 p-6">
            <div>{{ $t('general.loading')}}</div>
            <span class="loading loading-bars loading-sm"></span>
        </div>

        <div v-show="!isUserDetailsLoading" id="step-4" class="relative rounded-xl p-[30px] my-4 h-[250px] md:h-[350px] md:dark-mode-dashboard-banner" :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}">
            <div class="grid md:grid-cols-4 h-full">
                <!--left side-->
                <div class="md:col-span-2 md:my-auto">
                    <div class="">
                        <h2 class="text-xl md:text-3xl">{{ $t('dashboardBanner.welcome')}}, </h2>
                        <div class="text-3xl md:text-6xl font-bold py-1">{{ user.name }}</div>
                        <div class="hidden md:flex text-base uppercase py-2">
                            <p v-if="user.role_name === 'Murid' && user.media_group?.length > 0" class="pr-2">{{ user.media_group[0].name }}</p>
                            <p class="pl-2 border-l" v-if="user.school"> {{ user.school }}</p>
                        </div> 
                        <div class="flex-col md:hidden text-base uppercase py-2">
                            <p v-if="user.role_name === 'Murid' && user.media_group?.length > 0">{{ user.media_group[0].name }}</p>
                            <p v-if="user.school"> {{ user.school }}</p>
                        </div>
                    </div>
                </div>
                <!--right side-->
                <div class="col-span-1">
                    <div class="hidden md:block absolute top-0 right-0 h-full " >
                        <div class="relative">
                            <img :src="dashboardBannerImage" alt="welcome user card background" class="object-fill rounded-xl max-h-[350px]"> 
                            <div class="absolute top-[10%] right-[50%] bg-white max-w-48 p-2 rounded-lg shadow-lg">
                                <div class="relative flex gap-3">
                                    <div class="relative my-auto">
                                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.0209 3.03125C9.57301 3.03125 6.77092 5.83333 6.77092 9.28125V12.2917C6.77092 12.9271 6.50009 13.8958 6.17717 14.4375L4.97926 16.4271C4.23968 17.6563 4.75009 19.0208 6.10426 19.4792C10.5938 20.9792 15.4376 20.9792 19.9272 19.4792C21.1876 19.0625 21.7397 17.5729 21.0522 16.4271L19.8543 14.4375C19.5418 13.8958 19.2709 12.9271 19.2709 12.2917V9.28125C19.2709 5.84375 16.4584 3.03125 13.0209 3.03125Z" stroke="black" stroke-width="1.61" stroke-miterlimit="10" stroke-linecap="round"/>
                                            <path d="M14.9479 3.33325C14.6249 3.2395 14.2916 3.16659 13.9479 3.12492C12.9479 2.99992 11.9895 3.07284 11.0937 3.33325C11.3958 2.56242 12.1458 2.02075 13.0208 2.02075C13.8958 2.02075 14.6458 2.56242 14.9479 3.33325Z" stroke="black" stroke-width="1.61" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M16.1459 19.8542C16.1459 21.573 14.7396 22.9792 13.0209 22.9792C12.1667 22.9792 11.375 22.6251 10.8125 22.0626C10.25 21.5001 9.89587 20.7084 9.89587 19.8542" stroke="black" stroke-width="1.61" stroke-miterlimit="10"/>
                                        </svg>
                                        <div v-if="numOfUnreadNotification > 0" class="absolute -top-2 -right-1 bg-red-500 text-[10px] text-white rounded-full w-4 h-4 flex justify-center items-center">{{ numOfUnreadNotification }}</div>
                                    </div>
                                    <p class="text-black text-sm">{{ $t('dashboardBanner.notificationCount', {count: numOfUnreadNotification})}}</p>
                                    <svg class="absolute bottom-[-40%] right-3" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.5 14L0 0H16.5V14Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>    

                </div>
                <!-- <div class="md:hidden col-span-1">
                    <div class="absolute bottom-0 right-0 h-[350px] w-full" 
                    style="border-top-left-radius: 180px;
                            border-top-right-radius: 180px;" 
                    :class="{ 'bg-[#14B8A6]': themeStore.selectedTheme === 'green', 'bg-[#F4681A]': themeStore.selectedTheme === 'orange', 'bg-[#3B82F6]': themeStore.selectedTheme === 'blue', 'bg-[#8B5CF6]': themeStore.selectedTheme === 'purple', 'bg-[#DB2777]': themeStore.selectedTheme === 'darkpink'  }">
                    </div>
                    <div class="absolute buttom-0 right-1 h-full">
                        <div class="relative my-auto py-0">
                            <img src="../assets/Layer_1.png" alt="welcome user card" class="animate-float">
                            <div class="absolute top-[-20%] left-[-30%] bg-white max-w-48 p-2 rounded-lg">
                                <div class="relative flex gap-3">
                                    <div class="relative my-auto">
                                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.0209 3.03125C9.57301 3.03125 6.77092 5.83333 6.77092 9.28125V12.2917C6.77092 12.9271 6.50009 13.8958 6.17717 14.4375L4.97926 16.4271C4.23968 17.6563 4.75009 19.0208 6.10426 19.4792C10.5938 20.9792 15.4376 20.9792 19.9272 19.4792C21.1876 19.0625 21.7397 17.5729 21.0522 16.4271L19.8543 14.4375C19.5418 13.8958 19.2709 12.9271 19.2709 12.2917V9.28125C19.2709 5.84375 16.4584 3.03125 13.0209 3.03125Z" stroke="black" stroke-width="1.61" stroke-miterlimit="10" stroke-linecap="round"/>
                                            <path d="M14.9479 3.33325C14.6249 3.2395 14.2916 3.16659 13.9479 3.12492C12.9479 2.99992 11.9895 3.07284 11.0937 3.33325C11.3958 2.56242 12.1458 2.02075 13.0208 2.02075C13.8958 2.02075 14.6458 2.56242 14.9479 3.33325Z" stroke="black" stroke-width="1.61" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M16.1459 19.8542C16.1459 21.573 14.7396 22.9792 13.0209 22.9792C12.1667 22.9792 11.375 22.6251 10.8125 22.0626C10.25 21.5001 9.89587 20.7084 9.89587 19.8542" stroke="black" stroke-width="1.61" stroke-miterlimit="10"/>
                                        </svg>
                                        <div v-if="numOfUnreadNotification > 0" class="absolute -top-2 -right-1 bg-red-500 text-[10px] text-white rounded-full w-4 h-4 flex justify-center items-center">{{ numOfUnreadNotification }}</div>
                                    </div>
                                    <p class="text-black text-sm">{{ $t('dashboardBanner.notificationCount', {count: numOfUnreadNotification})}}</p>
                                    <svg class="absolute bottom-[-40%] right-3" width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.5 14L0 0H16.5V14Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div  >
            <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}">
                <div class="grid grid-cols-3 gap-4">
                    <div :class="[{'bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'dark-mode-metadata-bar-orange-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'orange'), 'dark-mode-metadata-bar-green-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'green'), 'dark-mode-metadata-bar-blue-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'blue'), 'dark-mode-metadata-bar-purple-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'purple'), 'dark-mode-metadata-bar-darkpink-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'darkpink')}]" class="p-4 rounded-3xl">
                        <div class="md:flex gap-4">
                            <div class="hidden md:block metadata-clock my-auto">
                                <svg v-if="modeStore.selectedMode === 'dark'" width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                                <path d="M27.3605 14.1012L21.498 13.1762C16.6105 12.4137 14.348 14.0637 13.573 18.9512L12.648 24.8137C12.148 28.0137 12.673 30.0887 14.4855 31.3512C15.4355 32.0262 16.7355 32.4762 18.423 32.7387L24.2855 33.6637C29.173 34.4262 31.4355 32.7762 32.2105 27.8887L33.123 22.0262C33.273 21.0637 33.3355 20.2012 33.2855 19.4387C33.123 16.3137 31.2855 14.7137 27.3605 14.1012ZM20.298 22.1887C18.8355 22.1887 17.648 21.0012 17.648 19.5512C17.648 18.0887 18.8355 16.9012 20.298 16.9012C21.748 16.9012 22.9355 18.0887 22.9355 19.5512C22.9355 21.0012 21.748 22.1887 20.298 22.1887Z" fill="white"/>
                                <path d="M35.6258 27.3367L33.7508 32.9742C32.1883 37.6742 29.6883 38.9242 24.9883 37.3617L19.3508 35.4867C17.5883 34.8992 16.3133 34.1742 15.4883 33.2617C16.2758 33.5742 17.1883 33.8117 18.2258 33.9742L24.1008 34.8992C24.9008 35.0242 25.6508 35.0867 26.3508 35.0867C30.4758 35.0867 32.6883 32.8617 33.4508 28.0742L34.3633 22.2117C34.4883 21.4867 34.5383 20.8492 34.5383 20.2617C36.4383 21.8242 36.7133 24.0492 35.6258 27.3367Z" fill="white"/>
                                </svg>
                                <svg v-else width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="0.5" width="50" height="50" rx="25" :fill=strokeColor fill-opacity="0.2"/>
                                <path d="M27.3605 14.1012L21.498 13.1762C16.6105 12.4137 14.348 14.0637 13.573 18.9512L12.648 24.8137C12.148 28.0137 12.673 30.0887 14.4855 31.3512C15.4355 32.0262 16.7355 32.4762 18.423 32.7387L24.2855 33.6637C29.173 34.4262 31.4355 32.7762 32.2105 27.8887L33.123 22.0262C33.273 21.0637 33.3355 20.2012 33.2855 19.4387C33.123 16.3137 31.2855 14.7137 27.3605 14.1012ZM20.298 22.1887C18.8355 22.1887 17.648 21.0012 17.648 19.5512C17.648 18.0887 18.8355 16.9012 20.298 16.9012C21.748 16.9012 22.9355 18.0887 22.9355 19.5512C22.9355 21.0012 21.748 22.1887 20.298 22.1887Z" :fill=strokeColor />
                                <path d="M35.6258 27.3367L33.7508 32.9742C32.1883 37.6742 29.6883 38.9242 24.9883 37.3617L19.3508 35.4867C17.5883 34.8992 16.3133 34.1742 15.4883 33.2617C16.2758 33.5742 17.1883 33.8117 18.2258 33.9742L24.1008 34.8992C24.9008 35.0242 25.6508 35.0867 26.3508 35.0867C30.4758 35.0867 32.6883 32.8617 33.4508 28.0742L34.3633 22.2117C34.4883 21.4867 34.5383 20.8492 34.5383 20.2617C36.4383 21.8242 36.7133 24.0492 35.6258 27.3367Z" :fill=strokeColor />
                                </svg>
                            </div>
                            <div class="md:hidden metadata-clock my-auto">
                                <svg v-if="modeStore.selectedMode === 'dark'" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="25" height="25" rx="12.5" fill="#FFF7ED" fill-opacity="0.2"/>
                                <path d="M13.6812 6.80033L10.75 6.33783C8.30624 5.95658 7.17499 6.78158 6.78749 9.22533L6.32499 12.1566C6.07499 13.7566 6.33749 14.7941 7.24374 15.4253C7.71874 15.7628 8.36874 15.9878 9.21249 16.1191L12.1437 16.5816C14.5875 16.9628 15.7187 16.1378 16.1062 13.6941L16.5625 10.7628C16.6375 10.2816 16.6687 9.85033 16.6437 9.46908C16.5625 7.90658 15.6437 7.10658 13.6812 6.80033ZM10.15 10.8441C9.41874 10.8441 8.82499 10.2503 8.82499 9.52533C8.82499 8.79408 9.41874 8.20033 10.15 8.20033C10.875 8.20033 11.4687 8.79408 11.4687 9.52533C11.4687 10.2503 10.875 10.8441 10.15 10.8441Z" fill="white"/>
                                <path d="M17.8125 13.4184L16.875 16.2371C16.0938 18.5871 14.8438 19.2121 12.4938 18.4309L9.67502 17.4934C8.79377 17.1996 8.15627 16.8371 7.74377 16.3809C8.13752 16.5371 8.59377 16.6559 9.11252 16.7371L12.05 17.1996C12.45 17.2621 12.825 17.2934 13.175 17.2934C15.2375 17.2934 16.3438 16.1809 16.725 13.7871L17.1813 10.8559C17.2438 10.4934 17.2688 10.1746 17.2688 9.88086C18.2188 10.6621 18.3563 11.7746 17.8125 13.4184Z" fill="white"/>
                                </svg>
                                <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="25" height="25" rx="12.5" :fill=strokeColor fill-opacity="0.2"/>
                                <path d="M13.6812 6.80033L10.75 6.33783C8.30624 5.95658 7.17499 6.78158 6.78749 9.22533L6.32499 12.1566C6.07499 13.7566 6.33749 14.7941 7.24374 15.4253C7.71874 15.7628 8.36874 15.9878 9.21249 16.1191L12.1437 16.5816C14.5875 16.9628 15.7187 16.1378 16.1062 13.6941L16.5625 10.7628C16.6375 10.2816 16.6687 9.85033 16.6437 9.46908C16.5625 7.90658 15.6437 7.10658 13.6812 6.80033ZM10.15 10.8441C9.41874 10.8441 8.82499 10.2503 8.82499 9.52533C8.82499 8.79408 9.41874 8.20033 10.15 8.20033C10.875 8.20033 11.4687 8.79408 11.4687 9.52533C11.4687 10.2503 10.875 10.8441 10.15 10.8441Z" :fill=strokeColor />
                                <path d="M17.8125 13.4184L16.875 16.2371C16.0938 18.5871 14.8438 19.2121 12.4938 18.4309L9.67502 17.4934C8.79377 17.1996 8.15627 16.8371 7.74377 16.3809C8.13752 16.5371 8.59377 16.6559 9.11252 16.7371L12.05 17.1996C12.45 17.2621 12.825 17.2934 13.175 17.2934C15.2375 17.2934 16.3438 16.1809 16.725 13.7871L17.1813 10.8559C17.2438 10.4934 17.2688 10.1746 17.2688 9.88086C18.2188 10.6621 18.3563 11.7746 17.8125 13.4184Z" :fill=strokeColor />
                                </svg>
                            </div>
                            <div>
                                <div v-if="userStats.total_added_item_count && userStats.total_added_item_count > 1" ><span class="font-semibold text-sm md:text-lg">{{ userStats.total_added_item_count || 0 }}</span> {{ $t('dashboardMetaDataBar.assets')}}</div>
                                <div v-else ><span class="font-semibold text-sm md:text-lg">{{ userStats.total_added_item_count || 0 }}</span> {{ $t('dashboardMetaDataBar.asset')}}</div>
                                <div class="font-semibold text-sm md:text-lg">{{ $t('dashboardMetaDataBar.inWorkspace')}}</div>
                            </div>
                            <!-- <div>
                                <div v-if="userStats.total_hour_spent && userStats.total_hour_spent > 1" ><span class="font-semibold text-lg">{{ userStats.total_hour_spent || 0 }}</span> {{ $t('dashboardMetaDataBar.hours')}}</div>
                                <div v-else ><span class="font-semibold text-lg">{{ userStats.total_hour_spent || 0 }}</span> {{ $t('dashboardMetaDataBar.hour')}}</div>
                                <div class="font-semibold">{{ $t('dashboardMetaDataBar.timespent')}}</div>
                            </div> -->
                            <!-- <div>
                                <div v-if="userStats.total_hour_spent && userStats.total_hour_spent > 1" ><span class="font-semibold text-sm md:text-lg">{{ userStats.total_hour_spent || 0 }}</span> {{ $t('dashboardMetaDataBar.minutes')}}</div>
                                <div v-else ><span class="font-semibold text-sm md:text-lg">{{ userStats.total_hour_spent || 0 }}</span> {{ $t('dashboardMetaDataBar.minute')}}</div>
                                <div class="font-semibold text-sm md:text-lg">{{ $t('dashboardMetaDataBar.timespent')}}</div>
                            </div> -->
                        </div>
                    </div>
                    <router-link :to="{'name': 'Playlist Page'}" :class="[{'bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'dark-mode-metadata-bar-orange-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'orange'), 'dark-mode-metadata-bar-green-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'green'), 'dark-mode-metadata-bar-blue-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'blue'), 'dark-mode-metadata-bar-purple-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'purple'), 'dark-mode-metadata-bar-darkpink-bg-2': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'darkpink')}]" class="p-4 rounded-3xl">
                        <div class="md:flex gap-4">
                            <div class="hidden md:block metadata-playlist my-auto">
                                <svg v-if="modeStore.selectedMode === 'dark'" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.667969" y="0.5" width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                                <path d="M25.668 13.0029C18.768 13.0029 13.168 18.6029 13.168 25.5029C13.168 32.4029 18.768 38.0029 25.668 38.0029C32.568 38.0029 38.168 32.4029 38.168 25.5029C38.168 18.6029 32.568 13.0029 25.668 13.0029ZM28.993 27.6654L27.393 28.5904L25.793 29.5154C23.7305 30.7029 22.043 29.7279 22.043 27.3529V25.5029V23.6529C22.043 21.2654 23.7305 20.3029 25.793 21.4904L27.393 22.4154L28.993 23.3404C31.0555 24.5279 31.0555 26.4779 28.993 27.6654Z" fill="white"/>
                                </svg>
                                <svg v-else width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.667969" y="0.5" width="50" height="50" rx="25" :fill=strokeColor fill-opacity="0.2"/>
                                <path d="M25.668 13.0029C18.768 13.0029 13.168 18.6029 13.168 25.5029C13.168 32.4029 18.768 38.0029 25.668 38.0029C32.568 38.0029 38.168 32.4029 38.168 25.5029C38.168 18.6029 32.568 13.0029 25.668 13.0029ZM28.993 27.6654L27.393 28.5904L25.793 29.5154C23.7305 30.7029 22.043 29.7279 22.043 27.3529V25.5029V23.6529C22.043 21.2654 23.7305 20.3029 25.793 21.4904L27.393 22.4154L28.993 23.3404C31.0555 24.5279 31.0555 26.4779 28.993 27.6654Z" :fill=strokeColor />
                                </svg>
                            </div>
                            <div class="md:hidden metadata-playlist my-auto">
                                <svg v-if="modeStore.selectedMode === 'dark'" width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.666687" width="25" height="25" rx="12.5" fill="#FFF7ED" fill-opacity="0.2"/>
                                <path d="M13.1667 6.25195C9.71669 6.25195 6.91669 9.05195 6.91669 12.502C6.91669 15.952 9.71669 18.752 13.1667 18.752C16.6167 18.752 19.4167 15.952 19.4167 12.502C19.4167 9.05195 16.6167 6.25195 13.1667 6.25195ZM14.8292 13.5832L14.0292 14.0457L13.2292 14.5082C12.1979 15.102 11.3542 14.6145 11.3542 13.427V12.502V11.577C11.3542 10.3832 12.1979 9.90195 13.2292 10.4957L14.0292 10.9582L14.8292 11.4207C15.8604 12.0145 15.8604 12.9895 14.8292 13.5832Z" fill="white"/>
                                </svg>
                                <svg v-else width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.666687" width="25" height="25" rx="12.5" :fill=strokeColor fill-opacity="0.2"/>
                                <path d="M13.1667 6.25195C9.71669 6.25195 6.91669 9.05195 6.91669 12.502C6.91669 15.952 9.71669 18.752 13.1667 18.752C16.6167 18.752 19.4167 15.952 19.4167 12.502C19.4167 9.05195 16.6167 6.25195 13.1667 6.25195ZM14.8292 13.5832L14.0292 14.0457L13.2292 14.5082C12.1979 15.102 11.3542 14.6145 11.3542 13.427V12.502V11.577C11.3542 10.3832 12.1979 9.90195 13.2292 10.4957L14.0292 10.9582L14.8292 11.4207C15.8604 12.0145 15.8604 12.9895 14.8292 13.5832Z" :fill=strokeColor />
                                </svg>
                            </div>
                            <div>
                                <div v-if="amtOfPlaylist && amtOfPlaylist.length > 1"><span class="font-semibold text-sm md:text-lg">{{ amtOfPlaylist.length }}</span> {{ $t('dashboardMetaDataBar.playlists')}}</div>
                                <div v-else ><span class="font-semibold text-sm md:text-lg">{{ 0 }}</span> {{ $t('dashboardMetaDataBar.playlist')}}</div>
                                <div class="font-semibold text-sm md:text-lg">{{ $t('dashboardMetaDataBar.fromTeacher')}}</div>
                            </div>
                        </div>
                    </router-link>
                    <router-link :to="{'name': 'Document Page'}" :class="[{'bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'dark-mode-metadata-bar-orange-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'orange'), 'dark-mode-metadata-bar-green-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'green'), 'dark-mode-metadata-bar-blue-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'blue'), 'dark-mode-metadata-bar-purple-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'purple'), 'dark-mode-metadata-bar-darkpink-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'darkpink')}]" class="p-4 rounded-3xl">
                        <div class="md:flex gap-4">
                            <div class="hidden md:block metadata-playlist my-auto">
                                <svg v-if="modeStore.selectedMode === 'dark'" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.332031" y="0.5" width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                                <path d="M30.0836 13.2607C29.5711 12.7482 28.6836 13.0982 28.6836 13.8107V18.1732C28.6836 19.9982 30.2336 21.5107 32.1211 21.5107C33.3086 21.5232 34.9586 21.5232 36.3711 21.5232C37.0836 21.5232 37.4586 20.6857 36.9586 20.1857C35.1586 18.3732 31.9336 15.1107 30.0836 13.2607Z" fill="white"/>
                                <path d="M35.957 23.236H32.3445C29.382 23.236 26.9695 20.8235 26.9695 17.861V14.2485C26.9695 13.561 26.407 12.9985 25.7195 12.9985H20.4195C16.5695 12.9985 13.457 15.4985 13.457 19.961V31.036C13.457 35.4985 16.5695 37.9985 20.4195 37.9985H30.2445C34.0945 37.9985 37.207 35.4985 37.207 31.036V24.486C37.207 23.7985 36.6445 23.236 35.957 23.236ZM24.707 32.686H19.707C19.1945 32.686 18.7695 32.261 18.7695 31.7485C18.7695 31.236 19.1945 30.811 19.707 30.811H24.707C25.2195 30.811 25.6445 31.236 25.6445 31.7485C25.6445 32.261 25.2195 32.686 24.707 32.686ZM27.207 27.686H19.707C19.1945 27.686 18.7695 27.261 18.7695 26.7485C18.7695 26.236 19.1945 25.811 19.707 25.811H27.207C27.7195 25.811 28.1445 26.236 28.1445 26.7485C28.1445 27.261 27.7195 27.686 27.207 27.686Z" fill="white"/>
                                </svg>
                                <svg v-else width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.332031" y="0.5" width="50" height="50" rx="25" :fill=strokeColor fill-opacity="0.2"/>
                                <path d="M30.0836 13.2607C29.5711 12.7482 28.6836 13.0982 28.6836 13.8107V18.1732C28.6836 19.9982 30.2336 21.5107 32.1211 21.5107C33.3086 21.5232 34.9586 21.5232 36.3711 21.5232C37.0836 21.5232 37.4586 20.6857 36.9586 20.1857C35.1586 18.3732 31.9336 15.1107 30.0836 13.2607Z" :fill=strokeColor />
                                <path d="M35.957 23.236H32.3445C29.382 23.236 26.9695 20.8235 26.9695 17.861V14.2485C26.9695 13.561 26.407 12.9985 25.7195 12.9985H20.4195C16.5695 12.9985 13.457 15.4985 13.457 19.961V31.036C13.457 35.4985 16.5695 37.9985 20.4195 37.9985H30.2445C34.0945 37.9985 37.207 35.4985 37.207 31.036V24.486C37.207 23.7985 36.6445 23.236 35.957 23.236ZM24.707 32.686H19.707C19.1945 32.686 18.7695 32.261 18.7695 31.7485C18.7695 31.236 19.1945 30.811 19.707 30.811H24.707C25.2195 30.811 25.6445 31.236 25.6445 31.7485C25.6445 32.261 25.2195 32.686 24.707 32.686ZM27.207 27.686H19.707C19.1945 27.686 18.7695 27.261 18.7695 26.7485C18.7695 26.236 19.1945 25.811 19.707 25.811H27.207C27.7195 25.811 28.1445 26.236 28.1445 26.7485C28.1445 27.261 27.7195 27.686 27.207 27.686Z" :fill=strokeColor />
                                </svg>
                            </div>
                            <div class="md:hidden metadata-playlist my-auto">
                                <svg v-if="modeStore.selectedMode === 'dark'" width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.333344" width="25" height="25" rx="12.5" fill="#FFF7ED" fill-opacity="0.2"/>
                                <path d="M15.2081 6.38057C14.9519 6.12432 14.5081 6.29932 14.5081 6.65557V8.83682C14.5081 9.74932 15.2831 10.5056 16.2269 10.5056C16.8206 10.5118 17.6456 10.5118 18.3519 10.5118C18.7081 10.5118 18.8956 10.0931 18.6456 9.84307C17.7456 8.93682 16.1331 7.30557 15.2081 6.38057Z" fill="white"/>
                                <path d="M18.1458 11.3678H16.3396C14.8583 11.3678 13.6521 10.1615 13.6521 8.68027V6.87402C13.6521 6.53027 13.3708 6.24902 13.0271 6.24902H10.3771C8.45209 6.24902 6.89584 7.49902 6.89584 9.73027V15.2678C6.89584 17.499 8.45209 18.749 10.3771 18.749H15.2896C17.2146 18.749 18.7708 17.499 18.7708 15.2678V11.9928C18.7708 11.649 18.4896 11.3678 18.1458 11.3678ZM12.5208 16.0928H10.0208C9.76459 16.0928 9.55209 15.8803 9.55209 15.624C9.55209 15.3678 9.76459 15.1553 10.0208 15.1553H12.5208C12.7771 15.1553 12.9896 15.3678 12.9896 15.624C12.9896 15.8803 12.7771 16.0928 12.5208 16.0928ZM13.7708 13.5928H10.0208C9.76459 13.5928 9.55209 13.3803 9.55209 13.124C9.55209 12.8678 9.76459 12.6553 10.0208 12.6553H13.7708C14.0271 12.6553 14.2396 12.8678 14.2396 13.124C14.2396 13.3803 14.0271 13.5928 13.7708 13.5928Z" fill="white"/>
                                </svg>
                                <svg v-else width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.333344" width="25" height="25" rx="12.5" :fill=strokeColor fill-opacity="0.2"/>
                                <path d="M15.2081 6.38057C14.9519 6.12432 14.5081 6.29932 14.5081 6.65557V8.83682C14.5081 9.74932 15.2831 10.5056 16.2269 10.5056C16.8206 10.5118 17.6456 10.5118 18.3519 10.5118C18.7081 10.5118 18.8956 10.0931 18.6456 9.84307C17.7456 8.93682 16.1331 7.30557 15.2081 6.38057Z" :fill=strokeColor />
                                <path d="M18.1458 11.3678H16.3396C14.8583 11.3678 13.6521 10.1615 13.6521 8.68027V6.87402C13.6521 6.53027 13.3708 6.24902 13.0271 6.24902H10.3771C8.45209 6.24902 6.89584 7.49902 6.89584 9.73027V15.2678C6.89584 17.499 8.45209 18.749 10.3771 18.749H15.2896C17.2146 18.749 18.7708 17.499 18.7708 15.2678V11.9928C18.7708 11.649 18.4896 11.3678 18.1458 11.3678ZM12.5208 16.0928H10.0208C9.76459 16.0928 9.55209 15.8803 9.55209 15.624C9.55209 15.3678 9.76459 15.1553 10.0208 15.1553H12.5208C12.7771 15.1553 12.9896 15.3678 12.9896 15.624C12.9896 15.8803 12.7771 16.0928 12.5208 16.0928ZM13.7708 13.5928H10.0208C9.76459 13.5928 9.55209 13.3803 9.55209 13.124C9.55209 12.8678 9.76459 12.6553 10.0208 12.6553H13.7708C14.0271 12.6553 14.2396 12.8678 14.2396 13.124C14.2396 13.3803 14.0271 13.5928 13.7708 13.5928Z" :fill=strokeColor />
                                </svg>
                            </div>
                            <div>
                                <div v-if="assetBroadcastTotal > 1"><span class="font-semibold text-sm md:text-lg">{{ assetBroadcastTotal }}</span> {{ $t('dashboardMetaDataBar.files')}}</div>
                                <div v-else ><span class="font-semibold text-sm md:text-lg">{{ assetBroadcastTotal }}</span> {{ $t('dashboardMetaDataBar.file')}}</div>
                                <div class="font-semibold text-sm md:text-lg">{{ $t('dashboardMetaDataBar.koleksiDelima')}}</div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- dashboard content -->
        <section
            class="flex flex-col gap-8 rounded-xl p-[30px] bg-transparent"
        >

            <div v-show="!isJustForYouListDataLoading && justForYouListData.length > 0">
              <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between">
                <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.justForYou')}}</h2>
              </div>

              <!-- <div v-show="isJustForYouListDataLoading" class="flex items-center justify-center w-full gap-2">
                <div>{{ $t('general.loading')}}</div>
                <span class="loading loading-bars loading-sm"></span>
              </div> -->

              <div v-show="isJustForYouListDataLoading" class="grid grid-cols-2 gap-y-12 md:grid-cols-4 md:gap-2 justify-items-center">
                <DocumentSkeletonCard v-for="asset in justForYouListData.slice(0, 4)" :key="asset.id" :dark="modeStore.selectedMode === 'dark'"/>
                <DocumentSkeletonCard v-for="asset in justForYouListData.slice(4, 8)" :key="asset.id" :dark="modeStore.selectedMode === 'dark'"/>
                <DocumentSkeletonCard v-for="asset in justForYouListData.slice(8, 12)" :key="asset.id" :dark="modeStore.selectedMode === 'dark'"/>
                <DocumentSkeletonCard v-for="asset in justForYouListData.slice(8, 12)" :key="asset.id" :dark="modeStore.selectedMode === 'dark'"/>
              </div>

              <div v-show="justForYouListData?.length > 0"
                   class="overflow-x-auto hide-scrollbar px-2"
              >
                <div class="flex items-center justify-start gap-2 flex-nowrap">
                    <!--reuse documents card as it is asset broadcasts, so it can add to playlist as well.-->
                    <DocumentsCard
                        class="min-w-[90%] md:min-w-[0px] w-full"
                        v-for="asset in justForYouListData.slice(0, 4)"
                        :key="asset.id"
                        :id="asset.id"
                        :image="asset.thumbnails[0]?.url"
                        :topic="asset.title"
                        :groups="asset.groups ? asset.groups : []"
                        :subjects="asset.subjects ? asset.subjects : []"
                        :postedTime="asset.created_at_formatted"
                        :tags="asset.tags"
                        :isAddedToWorkspace="asset.has_added_to_workspace"
                        :contentType="asset.content_type"
                        @refresh-asset-api="callJustForYouListApi"
                    />
                </div>
              </div>
            </div>

            <!-- Playlist -->
            <div id="step-5">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">Playlist</h2>
                    <router-link :to="{name: 'Playlist Page'}" class="title-action"><i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i></router-link>
                </div>

                <div v-show="isPlaylistLoading" class="grid md:grid-cols-3 justify-items-center gap-4">
                    <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 md:w-full"/>
                    <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 md:w-full"/>
                    <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 md:w-full"/>
                </div>

                <!-- <div v-show="!isPlaylistLoading" class="grid md:grid-cols-3 justify-items-center gap-4">
                    <PlaylistCard
                        class="min-w-full md:min-w-0 md:w-full"
                        v-for="playlist in playlistData.slice(0, 3)"
                        :key="playlist.id"
                        :playlistId="playlist.id"
                        :image="playlist.playlist_thumbnail"
                        :subject="playlist.title"
                        :teacher="playlist.created_by?.name"
                        :grade="playlist.access_groups"
                        :documentCount="playlist.document_count"
                        :interactionCount="playlist.interactive_count"
                        :videoCount="playlist.video_count"
                        :isAddedToWorkspace="playlist.has_added_to_workspace"
                        :isTeacherPlaylist="true"
                        @refresh-playlist-api="callPlaylistApi"
                    />
                </div> -->
                <div v-show="!isPlaylistLoading" class="overflow-x-auto hide-scrollbar px-2">
                    <div class="flex items-center justify-start gap-2 flex-nowrap">
                        <PlaylistCard
                            class="min-w-[90%] md:min-w-[0px] w-full"
                            v-for="playlist in playlistData.slice(0, 3)"
                            :key="playlist.id"
                            :playlistId="playlist.id"
                            :image="playlist.playlist_thumbnail"
                            :subject="playlist.title"
                            :teacher="playlist.created_by?.name"
                            :grade="playlist.access_groups"
                            :documentCount="playlist.document_count"
                            :interactionCount="playlist.interactive_count"
                            :videoCount="playlist.video_count"
                            :isAddedToWorkspace="playlist.has_added_to_workspace"
                            :isTeacherPlaylist="true"
                            @refresh-playlist-api="callPlaylistApi"
                        />
                    </div>
                </div>
            </div>

            <!-- Document -->
            <div id="step-3">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.DELIMaCollection')}}</h2>
                    <router-link :to="{name : 'Document Page'}" class="title-action"><i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i></router-link>
                </div>

                <!-- <div v-show="isAssetBroadcastsLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div> -->

                <div v-show="isAssetBroadcastsLoading" class="grid grid-cols-2 gap-y-12 md:grid-cols-4 md:gap-4 justify-items-center"
                :class="{'document-mobile-container' : isMobile}"
                >
                    <DocumentSkeletonCard v-for="asset in assetBroadcastsData.slice(0, 4)" :key="asset.id" :dark="modeStore.selectedMode === 'dark'"/>
                    <DocumentSkeletonCard v-for="asset in assetBroadcastsData.slice(0, 4)" :key="asset.id" :dark="modeStore.selectedMode === 'dark'"/>
                    <DocumentSkeletonCard v-for="asset in assetBroadcastsData.slice(0, 4)" :key="asset.id" :dark="modeStore.selectedMode === 'dark'"/>
                    <DocumentSkeletonCard v-for="asset in assetBroadcastsData.slice(0, 4)" :key="asset.id" :dark="modeStore.selectedMode === 'dark'"/>
                </div>

                <!-- <div v-show="!isAssetBroadcastsLoading" 
                    class="grid grid-cols-2 gap-y-12 md:grid-cols-4 md:gap-4 justify-items-center"
                    :class="{'document-mobile-container' : isMobile}"
                >
                    <DocumentsCard
                        class="md:min-w-0 lg:w-full"
                        v-for="asset in assetBroadcastsData.slice(0, 4)"
                        :key="asset.id"
                        :id="asset.id"
                        :image="asset.thumbnails[0]?.url"
                        :topic="asset.title"
                        :groups="asset.groups ? asset.groups : []"
                        :subjects="asset.subjects ? asset.subjects : []"
                        :postedTime="asset.created_at_formatted"
                        :tags="asset.tags"
                        :isAddedToWorkspace="asset.has_added_to_workspace"
                        :contentType="asset.content_type"
                        @refresh-asset-api="callAssetBroadcastApi"
                    />
                </div> -->
                <div v-show="!isAssetBroadcastsLoading" 
                    class="overflow-x-auto hide-scrollbar px-2"
                >
                    <div class="flex items-center justify-start gap-2 flex-nowrap">
                        <DocumentsCard
                            class="min-w-[90%] md:min-w-[0px] w-full"
                            v-for="asset in assetBroadcastsData.slice(0, 4)"
                            :key="asset.id"
                            :id="asset.id"
                            :image="asset.thumbnails[0]?.url"
                            :topic="asset.title"
                            :groups="asset.groups ? asset.groups : []"
                            :subjects="asset.subjects ? asset.subjects : []"
                            :postedTime="asset.created_at_formatted"
                            :tags="asset.tags"
                            :isAddedToWorkspace="asset.has_added_to_workspace"
                            :contentType="asset.content_type"
                            @refresh-asset-api="callAssetBroadcastApi"
                        />
                    </div>
                </div>
            </div>

            <!-- Teachers Avatar -->
            <div>
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.teacherList')}}</h2>
                    <router-link to="/teachers" class="title-action"><i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i></router-link>
                </div>

                <div v-show="isTeachersListDataLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div>

                <!-- <div v-show="!isTeachersListDataLoading" 
                    class="grid grid-cols-3 gap-x-4 gap-y-12 md:grid-cols-7 md:gap-4 justify-items-center"
                    :class="{'avatar-mobile-container' : isMobile}"
                >
                    <TeachersCard
                        class="w-full md:min-w-0 lg:w-[80%]"
                        v-for="teacher in teachersListData"
                        :key="teacher.id"
                        :image="teacher.avatar_url"
                        :name="teacher.name"
                        :role="teacher.role_name"
                        :id="teacher.id"
                    />
                </div> -->

                <div v-show="!isTeachersListDataLoading" class="overflow-x-auto hide-scrollbar px-2">
                    <div class="flex items-center justify-start gap-2 flex-nowrap">
                        <div v-for="teacher in teachersListData" :key="teacher.id" class="flex-shrink-0">
                            <TeachersCard
                                class="min-w-[90%] md:min-w-[0px] w-full"
                                :image="teacher.avatar_url"
                                :name="teacher.name"
                                :role="teacher.role_name"
                                :id="teacher.id"
                            />
                        </div>
                    </div>
                </div>

            </div>

            <!-- Recently watched (Baru ditonton) -->
            <div>
                <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between">
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.recentlyWatched')}}</h2>
                    <!--hide 'lihat lagi' first as we dun hv recently watch page-->
                    <!-- <div class="title-action">{{ $t('general.seeMore') }}</div> -->
                </div>

                <div v-show="isRecentlyWatchedListDataLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div>

                <div v-show="!isRecentlyWatchedListDataLoading"
                    class="overflow-x-auto hide-scrollbar px-2"
                >
                    <div class="flex items-center justify-start gap-2 flex-nowrap">
                        <RecentlyWatched
                        class="min-w-[90%] md:min-w-[0px] w-full"
                            v-for="data in recentlyWatchedListData"
                            :key="data.playlist_id"
                            :type="data.playlist_type"
                            :playlistId="data.playlist_id"
                            :userPlaylistId="data.user_playlist_id"
                            :assetBroadcastId="data.latest_asset_broadcast.asset_broadcast_id"
                            :image="(data.playlist_type == 'AssetBroadcast') ? data.asset_broadcast_thumbnail : data.playlist_thumbnail_url"
                            :title="(data.playlist_type =='AssetBroadcast') ? data.asset_broadcast_name : data.playlist_name"
                            :elapsedTime="data.created_at_formatted"
                            :minutes="data.latest_asset_broadcast.asset_type == 1 ? 75 : null"
                            :currentSeconds="data.latest_asset_broadcast.asset_type == 1 ? data.latest_asset_broadcast.current_seconds : null"
                            :isTeacherPlaylist="data.playlist_type == 'Playlist'"
                            :assetId="data.latest_asset_broadcast.asset_broadcast_id"
                            :playFrom="data.latest_asset_broadcast?.asset_broadcast_id.current_seconds"
                            :playlistType="data.playlist_type"
                        />
                    </div>
                </div>
            </div>

        </section>

        <!-- Onboarding Wrapper -->
        <div>
            <VOnboardingWrapper ref="wrapper" :steps="steps">
                <template #default="{ next, step }">
                    <VOnboardingStep>
                        <div class="bg-[#272423] text-white shadow sm:rounded-lg w-[30rem] flex flex-col z-50">
                            <button @click="endTour" type="button" class="flex w-full rounded px-6 z-50 mt-6 items-center justify-end">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.866117 0.866117C1.35427 0.377961 2.14573 0.377961 2.63388 0.866117L8 6.23223L13.3661 0.866117C13.8543 0.377961 14.6457 0.377961 15.1339 0.866117C15.622 1.35427 15.622 2.14573 15.1339 2.63388L9.76777 8L15.1339 13.3661C15.622 13.8543 15.622 14.6457 15.1339 15.1339C14.6457 15.622 13.8543 15.622 13.3661 15.1339L8 9.76777L2.63388 15.1339C2.14573 15.622 1.35427 15.622 0.866117 15.1339C0.377961 14.6457 0.377961 13.8543 0.866117 13.3661L6.23223 8L0.866117 2.63388C0.377961 2.14573 0.377961 1.35427 0.866117 0.866117Z" 
                                    fill="white"/>
                                </svg>
                            </button>
                            <div class="px-4 py-5 sm:p-6">
                                <div class="flex flex-col sm:flex sm:items-center sm:justify-between">
                                    <div v-if="step.content">
                                        <h3 v-if="step.content.title" class="text-lg font-medium leading-6 text-white">{{ step.content.title }}</h3>
                                    </div>
                                    <div class="flex items-center justify-center w-full py-5">
                                        <div class="flex items-center justify-center w-full py-5" v-html="stepIndicatorSvg(step.index)"></div>
                                    </div>
                                    <div class="flex flex-row items-center justify-between relative w-full">
                                        <!-- <span class="absolute right-0 bottom-full mb-2 mr-2 text-gray-600 font-medium text-xs">{{ `${index + 1}/${steps.length}` }}</span> -->
                                        <!-- <template v-if="!isFirst">
                                            <button @click="previous" type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-100 px-4 py-2 font-medium text-yellow-700 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:text-sm">Previous</button>
                                        </template> -->
                                        <div v-if="step.content.description" class="max-w-xl text-sm text-white">
                                            <button @click="endTour" type="button">{{ step.content.description }}</button>
                                        </div>
                                        <button
                                            @click="next" type="button"
                                            class="inline-flex items-center rounded-md border border-transparent px-4 py-3 font-medium text-white shadow-sm focus:outline-none sm:text-sm"
                                            :class="bgColor"
                                        >
                                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.42969 20.75L16.2214 13.9583C17.0234 13.1562 17.0234 11.8438 16.2214 11.0417L9.42969 4.25" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </VOnboardingStep>                   
                </template>
            </VOnboardingWrapper>
        </div>
    </div>
</template>

<style>
[data-v-onboarding-wrapper] [data-popper-arrow]::before {
  content: '';
  background: var(--v-onboarding-step-arrow-background, white);
  top: 0;
  left: 0;
  transition: transform 0.2s ease-out,visibility 0.2s ease-out;
  visibility: visible;
  transform: translateX(0px) rotate(45deg);
  transform-origin: center;
  width: var(--v-onboarding-step-arrow-size, 10px);
  height: var(--v-onboarding-step-arrow-size, 10px);
  position: absolute;
  z-index: -1;
}

[data-v-onboarding-wrapper] [data-popper-placement^='top'] > [data-popper-arrow] {
  bottom: 5px;
}

[data-v-onboarding-wrapper] [data-popper-placement^='right'] > [data-popper-arrow] {
  left: -4px;
}

[data-v-onboarding-wrapper] [data-popper-placement^='bottom'] > [data-popper-arrow] {
  top: -4px;
}

[data-v-onboarding-wrapper] [data-popper-placement^='left'] > [data-popper-arrow] {
  right: -4px;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}
.animate-float {
    animation: float 3s ease-in-out infinite;
}
</style>