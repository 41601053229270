import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
// import LanguageDetector from 'i18next-browser-languagedetector'

let storedLanguage = localStorage.getItem('selectedLanguage')
let language = storedLanguage ? JSON.parse(storedLanguage).selectedLanguage : 'en'

switch (language) {
  case 'English':
    language = 'en'
    break
  case 'Bahasa':
    language = 'bm'
    break
  default:
    language = 'bm'
    break
}

i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: language,
    debug: true,
    fallbackLng: 'bm',
    interpolation: { escapeValue: false },
    resources: {
      en: {
        translation: {
          error: {
            somethingWentWrong: 'Sorry, Something went wrong',
            backToDashboard: 'Back to Dashboard',
          },
          loginPage: {
            welcomeToDelimaFlix: 'Welcome to DelimaFlix',
            description: 'Ready to embark on a thrilling journey into the world of learning, specially crafted for you? We\'ve curated a range of captivating resources guaranteed to enhance your learning experience. So, what are you waiting for? Let\'s dive in and discover the joy of learning together with DelimaFlix!',
            loginToProceed: 'Log in to proceed to Delimaflix',
            email: 'Email',
            password: 'Password',
            rememberMe: 'Remember Me',
            forgotPassword: 'Forgot Password?',
            login: 'Sign Up',
            or: 'or',
            loginWithGoogle: 'Log in with Google',
          },
          general: {
            seeMore: 'See More',
            by: 'by',
            document: 'Document',
            interactive: 'Interactive',
            video: 'Video',
            message: 'Message',
            loading: 'Loading...',
            noData: 'No data',
            add: 'Add',
            cancel: 'Cancel',
            save: 'Save',
            change: 'Change',
            create: 'Create',
            confirm: 'Confirm',
          },
          search: {
            startSearch: 'Start search',
            topResult: 'Top Result',
            viewAllResults: 'View All Results',
          },
          notification: {
            notification: 'Notification',
            allNotifications: 'All Notifications',
            unread: 'Unread',
            read: 'Read',
          },
          title: {
            DELIMaCollection: 'Main Collections',
            teacherList: 'List Of Teachers',
            recentlyWatched: 'Recently Watched',
            workspace: 'Workspace',
            newPublish: 'New Publish',
            browse: 'Browse',
            justForYou: 'Just For You',
            file: 'File',
            playlist: 'Playlist',
            document: 'Documents',
          },
          sideMenu: {
            PapanPemuka: 'Dashboard',
            Browse: 'Browse',
            RuangKerja: 'Workspace',
            DELIMaCollection: 'Main Collections',
            Playlist: 'Playlist',
            MaklumBalas: 'Feedback',
            Podcast: 'Podcast',
            Tetapan: 'Setting',
          },
          langThemeSignOut: {
            logout: 'Log out',
            signOut: 'Sign out',
            bright: 'Bright',
            light: 'Light',
            dark: 'Dark',
          },
          onboarding: {
            chooseAUserGroup: 'Choose Your Year / Grade',
            welcomeToDelimaFlix: 'Hi! Welcome to DelimaFlix 👋',
            greeting: 'Hi!👋',
            message: 'We are glad you joined us! DelimaFlix is specially designed to enrich your learning experience. Let\'s explore and enjoy every moment of learning together with us!',
            startOnboarding: 'Start Onboarding',
            chosen_user_group: 'Welcome, you are now in {{user_group}}',
            next: 'Next',
            sideMenu: 'Use the expandable side menu for easy access to your dashboard and to manage your playlists quickly. Here, you can explore subjects, organize playlists, and much more. Happy learning!',
            topHeader: 'Students can access their playlists here. This space makes it easy for you to resume interrupted lessons or start new topics smoothly, making your learning experience more organized and efficient.',
            minimizePlaylist: 'This interesting feature allows you to minimize the currently playing playlist into a mini video at the bottom of the screen. With this, you can continue enjoying the playlist while exploring other areas on DelimaFlix without interrupting your learning experience!',
            banner: 'View all your learning activities on the User Dashboard. Here, you can check playlists and statistics, making it easier for you to monitor your own activities and stay active in your educational journey.',
            playlist: 'This section of the playlist makes it easy for you to choose playlists that have been customized and specially arranged to meet your learning needs!',
            langTheme: 'To change the language on Delimaflix, click on the language option in the top right corner and choose between Bahasa Malaysia or English. You can also switch the background theme from light to dark or select a suitable color for your comfort.',
            workspace: 'Click the workspace button to add to the workspace subjects you\'ve watched, liked, or want to focus on, as well as a list of subject displays from all files. It\'s like a bookmark that makes it easy for you!',
            skipTour: 'Skip'
          },
          pagination: {
            details: 'Displaying {{firstItem}} to {{lastItem}} of {{totalItems}} results',
          },
          dashboardBanner: {
            hoursWord: 'Hours',
            welcome: 'Welcome',
            hours: 'Hours of time spent',
            playlistFromTeacher: 'Playlists from teachers',
            likedPlaylist: 'Favorite playlists',
            notificationCount: 'You have {{count}} notifications',
            itemsAddedToWorkspace: 'Items added to workspace',
          },
          dashboardMetaDataBar: {
            minute: 'Minute',
            minutes: 'Minutes',
            hour: 'Hour',
            hours: 'Hours',
            timespent: 'Spent Time',
            playlist: 'Playlist',
            playlists: 'Playlists',
            fromTeacher: 'From Teachers',
            file: 'File',
            files: 'Files',
            koleksiDelima: 'Delima Collections',
            asset: 'Asset',
            assets: 'Assets',
            inWorkspace: 'In Workspace',
          },
          documentPage: {
            filter: 'Filter',
            resetFilter: 'Reset filter',
            title: 'Title',
            fileType: 'File Type',
            dateAdded: 'Date Added',
            duration: 'Duration',
            action: 'Action',
            all: 'All',
            uploadedBy: 'Uploaded By',
            grade: 'Grade',
            subject: 'Subject',
            uploadDate: 'Upload Date'
          },
          playlistPage: {
            playlist: 'Playlist',
            all: 'All',
            teacher: 'Teacher',
            myself: 'Myself',
            yours: 'Yours',
            teacherPlaylist: 'Teacher\'s Playlist',
            createPlaylist: 'Create Playlist',
            title: 'Title',
            fileType: 'File Type',
            dateAdded: 'Date Added',
            action: 'Action',
            addToPlaylist: 'Add to Playlist',
            addPlaylist: 'Add Playlist',
            addedToPlaylist: 'Added to Playlist',
            noPlaylistFound: 'No playlist found',
            editPlaylist: 'Edit Playlist',
            addedVideo: 'Added File',
          },
          feedback: {
            feedback: 'Feedback',
            inbox: 'Inbox',
            sentMail: 'Sent Mail',
            archive: 'Archive',
            writeMessage: 'Write Message',
            createFeedback: 'Create Feedback',
            receiver: 'Receiver',
            subject: 'Subject',
            feedbackTitle: 'Please Provide Your Feedback',
            select: 'Select',
            send: 'Send',
            all: 'All',
            read: 'Read',
            unread: 'Unread',
            selectMsgForMoreAction: 'Select messages to see more actions',
            markAllRead: 'Mark all as read',
            markAllUnread: 'Mark all as unread',
            noMessageFound: 'No message found',
            noSentMail: 'No sent mail',
            noArchivedMail: 'No archived mail',
          },
          setting: {
            settings: 'Settings',
            profileSetting: 'Profile Settings',
            personalInformation: 'Personal Information',
            fullName: 'Full Name',
            schoolName: 'School Name',
            level: 'Level',
            grade: 'Grade',
            gender: 'Gender',
            male: 'Male',
            female: 'Female',
            emailAddress: 'Email Address',
            stream: 'Stream',
          }
        }
      },
      bm: {
        translation: {
          error: {
            somethingWentWrong: 'Maaf, sesuatu telah berlaku.',
            backToDashboard: 'Kembali ke Halaman Utama',
          },
          loginPage: {
            welcomeToDelimaFlix: 'Selamat Datang ke DelimaFlix',
            description: 'Bersedia untuk melangkah ke dalam dunia pembelajaran yang penuh dengan keseronokan, yang telah direka khas untuk anda. Kami telah menyediakan pelbagai bahan yang menarik yang pastinya akan membuat sesi pembelajaran anda lebih menarik. Jadi, tunggu apa lagi? Mari kita mula meneroka dan rasai sendiri keseronokan belajar bersama DelimaFlix!',
            loginToProceed: 'Log masuk, untuk meneruskan ke Delimaflix',
            email: 'Emel',
            password: 'Eata Ealuan',
            rememberMe: 'Ingat Saya',
            forgotPassword: 'Lupa Kata Laluan?',
            login: 'Daftar Masuk',
            or: 'atau ',
            loginWithGoogle: 'Log Masuk Dengan Google',
          },
          general: {
            seeMore: 'Lihat Lagi',
            by: 'oleh',
            document: 'Dokumen',
            interactive: 'Interaktif',
            video: 'Video',
            message: 'Mesej',
            loading: 'Memuatkan...',
            noData: 'Tiada data',
            add: 'Tambah',
            cancel: 'Batal',
            save: 'Simpan',
            change: 'Tukar',
            create: 'Cipta',
            confirm: 'Sahkan',
          },
          search: {
            startSearch: 'Mulakan carian anda...',
            topResult: 'Pencarian Teratas',
            viewAllResults: 'Lihat Semua Pencarian',
          },
          notification: {
            notification: 'Notifikasi',
            allNotifications: 'Semua Notifikasi',
            unread: 'Belum Dibaca',
            read: 'Sudah Dibaca',
          },
          title: {
            DELIMaCollection: 'Koleksi Utama',
            teacherList: 'Senarai Cikgu',
            recentlyWatched: 'Baru Ditonton',
            workspace: 'Ruang Kerja',
            newPublish: 'Terbitan Baru',
            browse: 'Browse',
            justForYou: 'Hanya Untuk Anda',
            file: 'Fail',
            playlist: 'Playlist',
            document: 'Koleksi Utama',
          },
          sideMenu: {
            PapanPemuka: 'Papan Pemuka',
            Browse: 'Browse',
            RuangKerja: 'Ruang Kerja',
            DELIMaCollection: 'Koleksi Utama',
            Playlist: 'Playlist',
            MaklumBalas: 'Maklum Balas',
            Podcast: 'Podcast',
            BukuTeks: 'Buku Teks',
            Tetapan: 'Tetapan',
          },
          langThemeSignOut: {
            logout: 'Log Keluar',
            signOut: 'Log Keluar',
            bright: 'Cerah',
            light: 'Cerah',
            dark: 'Gelap',
          },
          onboarding: {
            chooseAUserGroup: 'Sila Isi Tingkatan / Tahun Anda',
            welcomeToDelimaFlix: 'Hai, Selamat Datang ke DELIMaFlix! 👋',
            greeting: 'Hai!👋',
            chosen_user_group: 'Tahniah, anda kini di {{user_group}}',
            message: 'Kami gembira anda sertai kami! DelimaFlix dibina khusus untuk memperkaya pengalaman pembelajaran anda.Mari meneroka dan nikmati setiap detik pembelajaran bersama kami!',
            startOnboarding: 'Mulakan Onboarding',
            next: 'Seterusnya',
            sideMenu: 'Gunakan menu sisi yang boleh dibuka dan ditutup untuk akses mudah ke papan pemuka dan urus playlist anda dengan cepat. Di sini, anda boleh meneroka subjek, mengatur playlist, dan banyak lagi. Selamat belajar!',
            topHeader: 'Para pelajar boleh akses playlist mereka di sini. Ruangan ini memudahkan anda untuk menyambung semula pelajaran yang terhenti atau memulakan topik baru dengan lancar, menjadikan pengalaman pembelajaran anda lebih teratur dan efisien.',
            minimizePlaylist: 'Ciri menarik ini membolehkan anda mengecilkan playlist yang sedang dimainkan menjadi video mini di bahagian bawah skrin. Dengan ini, anda boleh terus menikmati playlist sambil meneroka kawasan lain di DelimaFlix tanpa mengganggu pengalaman belajar anda!',
            banner: 'Lihat semua aktiviti pembelajaran anda pada Papan Pemuka Pengguna. Di sini, anda boleh semak playlist dan data statistik, yang memudahkan anda untuk pantau aktiviti sendiri dan aktif dalam perjalanan pendidikan anda.',
            playlist: 'Bahagian playlist ini memudahkan anda memilih playlist yang telah disesuaikan dan disusun khas untuk memenuhi keperluan pembelajaran anda!',
            langTheme: 'Untuk menukar bahasa di Delimaflix, klik pada pilihan bahasa di sudut kanan atas dan pilih antara Bahasa Malaysia atau English. Anda juga boleh menukar tema latar belakang dari terang ke gelap atau pilih warna yang sesuai untuk keselesaan anda.',
            workspace: 'Klik butang ruang kerja ini untuk tambahkan ke dalam ruang kerja subjek yang telah anda tonton, sukai, atau yang ingin anda fokuskan, serta senarai paparan subjek-subjek dari semua fail. Ia adalah seperti “bookmark” yang memudahkan anda!',
            skipTour: 'Langkau',
          },
          pagination: {
            details: 'Memaparkan {{firstItem}} hingga {{lastItem}} daripada {{totalItems}} hasil',
          },
          dashboardBanner: {
            hoursWord: 'Jam',
            welcome: 'Selamat Datang',
            hours: 'Jam masa yang diluangkan',
            playlistFromTeacher: 'Playlist daripada guru',
            likedPlaylist: 'Playlist yang digemari',
            notificationCount: 'Anda mempunyai {{count}} notifikasi',
            itemsAddedToWorkspace: 'Item ditambah ke ruang kerja',
          },
          dashboardMetaDataBar: {
            minute: 'Minit',
            minutes: 'Minit',
            hour: 'Jam',
            hours: 'Jam',
            timespent: 'Yang Diluangkan',
            playlist: 'Playlist',
            playlists: 'Playlists',
            fromTeacher: 'Daripada Guru',
            file: 'Fail',
            files: 'Fail',
            koleksiDelima: 'Koleksi Utama',
            asset: 'Aset',
            assets: 'Aset',
            inWorkspace: 'dalam Ruang Kerja',
          },
          documentPage: {
            filter: 'Penapis',
            resetFilter: 'Tetapkan semula penapis',
            title: 'Tajuk',
            fileType: 'Jenis Fail',
            dateAdded: 'Tarikh Ditambah',
            duration: 'Tempoh',
            action: 'Tindakan',
            all: 'Semua',
            uploadedBy: 'Dinaikkan Oleh',
            grade: 'Tingkatan/Tahun',
            subject: 'Subjek',
            uploadDate: 'Tarikh Dinaikkan',
          },
          playlistPage: {
            playlist: 'Senarai Playlist',
            all: 'Semua',
            teacher: 'Cikgu',
            myself: 'Sendiri',
            yours: 'Anda',
            teacherPlaylist: 'Playlist Cikgu',
            createPlaylist: 'Cipta Playlist',
            title: 'Tajuk',
            fileType: 'Jenis Fail',
            dateAdded: 'Tarikh Ditambah',
            action: 'Tindakan',
            addToPlaylist: 'Tambah ke Playlist',
            addPlaylist: 'Tambah Playlist',
            addedToPlaylist: 'Ditambah ke Playlist',
            noPlaylistFound: 'Tiada playlist ditemui',
            editPlaylist: 'Sunting Playlist',
            addedVideo: 'Koleksi Fail Yang Ditambah',
          },
          podcastPage:{
            welcome: 'Selamat Datang ke Podcast',
            all: 'Semua',
          },
          textbookPage:{
            welcome: 'Selamat Datang ke Interaktif Buku Teks',
            all: 'Semua',
          },
          feedback: {
            feedback: 'Maklum Balas',
            inbox: 'Peti Masuk',
            sentMail: 'Dihantar',
            archive: 'Arkib',
            writeMessage: 'Tulis Mesej',
            createFeedback: 'Cipta Maklum Balas',
            receiver: 'Kepada',
            subject: 'Tajuk',
            feedbackTitle: 'Berikan Maklum Balas Anda',
            select: 'Pilih',
            send: 'Hantar',
            all: 'Semua',
            read: 'Dibaca',
            unread: 'Belum Dibaca',
            selectMsgForMoreAction: 'Pilih mesej untuk melihat lebih banyak tindakan',
            markAllRead: 'Tandakan semua sebagai dibaca',
            markAllUnread: 'Tandakan semua sebagai belum dibaca',
            noMessageFound: 'Tiada mesej ditemui',
            noSentMail: 'Tiada mesej dihantar',
            noArchivedMail: 'Tiada mesej diarkibkan',
          },
          setting: {
            settings: 'Tetapan',
            profileSetting: 'Tetapan Profil',
            personalInformation: 'Biodata Diri',
            fullName: 'Nama Penuh',
            schoolName: 'Nama Sekolah',
            level: 'Tahap',
            grade: 'Tingkatan/Tahun',
            gender: 'Jantina',
            male: 'Lelaki',
            female: 'Perempuan',
            emailAddress: 'Alamat Emel',
            stream: 'Aliran',
          }
        }
      }
    }
  });

export default function (app) {
  app.use(I18NextVue, { i18next })
  return app
}